import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomCheckbox = styled(Checkbox)({
  color: "#33a6cd",
  "&.Mui-checked": {
    color: "#33a6cd",
  },
  "& .MuiSvgIcon-root": {
    borderColor: "#33a6cd",
  },
  "&:not(.Mui-checked) .MuiSvgIcon-root": {
    border: `1px solid #33a6cd`,
    borderRadius: "2px",
  },
});

const UserPasswordForm = ({
  inputs,
  setInputs,
  isChecked,
  setIsChecked,
  setSubmitEnabled,
}) => {
  const [errors, setErrors] = useState({});
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    setErrors((prevErrors) => ({ ...prevErrors, checkbox: "" }));
  };

  // Terms and conditions checkbox validation
  if (!isChecked) {
    // newErrors.checkbox = "You must agree to the terms and conditions.";
    // isValid = false;
  }

  useEffect(() => {
    if (
      inputs.password &&
      inputs.passwordConfirm &&
      inputs.password === inputs.passwordConfirm
    ) {
      setSubmitEnabled(true);
    } else {
      setSubmitEnabled(false);
    }
  }, [inputs, setSubmitEnabled]);

  return (
    <>
      <Row className="w-100" style={{ height: "250px" }}>
        <Col md={6}>
          <label>
            Password <span className="text-danger">*</span>
          </label>
          <input
            type="password"
            name="password"
            value={inputs.password}
            placeholder="Enter password"
            onChange={handleChange}
            style={{ borderRadius: 7 }}
            className="mb-3 form-control fromInput"
            required
          />
        </Col>
        <Col md={6}>
          <label>
            Confirm password <span className="text-danger">*</span>
          </label>
          <input
            type="password"
            name="passwordConfirm"
            value={inputs.passwordConfirm}
            placeholder="Enter confirm password"
            onChange={handleChange}
            style={{ borderRadius: 7 }}
            className="mb-3 form-control fromInput"
            required
          />
        </Col>
        <Col md={12}>
          <div className="w-100 mt-2 mb-2 d-flex align-items-center">
            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
              }
              label={
                <Typography variant="body2" className="mt-2 text-dark">
                  By Agreeing you accept to receive noitification and email
                  marketing for Kago Human Capital.
                </Typography>
              }
              style={{ alignItems: "start" }}
            />
            {/* {errors?.checkbox && (
              <div className="text-danger">{errors.checkbox}</div>
            )} */}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default UserPasswordForm;
