import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import useCustomMutation from "../../../lib/Hooks/useCustomMutation";
import DataTable from "react-data-table-component";
import OffCanvas from "../../Common/OffCanvas/OffCanvas";
import { roleTblTitle, employeeTbl } from "../../Common/TableStyle";
// import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import SearchInput from "../../Common/SearchIput";
import ButtonSubmit from "../../Common/Buttons/ButtonSubmit";

const Roles = ({ organizationID, roles, token }) => {
  const [search, setSearch] = useState("");
  const [inputs, setInputs] = useState({});
  const [inputsDelete, setInputsDelete] = useState({
    name: "",
  });
  // const [roleID, setRoleID] = useState(null);
  const [roleName, setRoleName] = useState(null);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isAddRoles, setIsAddRoles] = useState(false);
  const [isEditRoles, setIsEditRoles] = useState(false);

  const key = ["CompanyDetails", organizationID];
  const deleteMutation = useCustomMutation(key);
  const addMutation = useCustomMutation(key);

  const toggleAdd = (id) => {
    // setRoleID(id);
    setIsAddRoles(!isAddRoles);
  };

  const toggleDeleteModal = (name) => {
    // setRoleID(id);
    setRoleName(name);
    setIsDeleteModal(!isDeleteModal);
  };

  const columns = [
    // {
    //   name: <span className="font-weight-bold fs-13">ID</span>,
    //   selector: (row) => row?.id,
    // },
    {
      name: <span className="font-weight-bold fs-13">Role</span>,
      selector: (row) => row?.name,
    },
    {
      name: <span className="font-weight-bold fs-13">Review period</span>,

      cell: (row) => {
        return (
          <div style={{ display: "flex", alignItems: "start" }}>
            <div>
              {row?.period === "Monthly"
                ? row?.period
                : row?.period === "3-Months"
                ? "3 Months"
                : row?.period === "4-Months"
                ? "4 Months"
                : row?.period === "6-Months"
                ? "6 Months"
                : null}
            </div>
          </div>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Level</span>,
      selector: (row) => row?.level,
    },

    {
      name: <span className="font-weight-bold fs-13">Action</span>,

      cell: (row) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <MdDelete
                size={18}
                className="mx-1"
                onClick={() => toggleDeleteModal(row?.name)}
              />
            </div>
          </div>
        );
      },
    },
  ];

  const filterRole = (employeeData, searchQuery) => {
    if (!searchQuery) return employeeData;
    return employeeData?.filter((item) =>
      [item?.name, item?.reviewPeriod].some((field) =>
        field?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  };

  const roleTable = filterRole(roles, search);

  const data = roleTable || [];

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  const handleDeleteChange = (event) => {
    const { name, value } = event.target;
    setInputsDelete((inputsDelete) => ({ ...inputsDelete, [name]: value }));
  };

  const handleAddRole = (e) => {
    e.preventDefault();
    const Method = "PATCH",
      url = `organizations/${organizationID}/roles-add`;
    const raw = JSON.stringify({
      name: inputs.name,
      period: inputs.period,
      level:
        inputs.name === "Learner"
          ? 3
          : inputs.name === "Intern"
          ? 3
          : inputs.name === "Employee"
          ? 3
          : inputs.name === "Manager"
          ? 2
          : null,
    });
    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Role successfully added",
      token: token,
    };
    addMutation.mutate(data);
  };

  const handleDeleteRole = (e) => {
    e.preventDefault();
    const Method = "PATCH",
      url = `organizations/${organizationID}/roles-remove?role=${inputsDelete?.name}`;
    const raw = null;
    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Role successfully deleted",
      token: token,
    };
    deleteMutation.mutate(data);
  };

  useEffect(() => {
    if (addMutation?.isSuccess) {
      setInputs({});
      setIsAddRoles(false);
    }

    if (deleteMutation?.isSuccess) {
      setRoleName(null);
      setInputsDelete({});
      setIsDeleteModal(false);
    }
  }, [addMutation?.isSuccess, deleteMutation?.isSuccess]);

  return (
    <>
      <Row>
        <Col md={12}>
          <div className="w-100 mb-2 d-flex justify-content-center">
            <h4>Roles</h4>
          </div>
        </Col>
        <Col md={12}>
          <div className="w-100 d-flex justify-content-center">
            <div style={{ width: "90%" }}>
              <Card style={employeeTbl}>
                <CardBody>
                  <div style={roleTblTitle}>
                    <div>
                      <SearchInput
                        Title={"Search"}
                        search={search}
                        setSearch={setSearch}
                        radius={20}
                      />
                    </div>

                    <div>
                      <ButtonSubmit
                        Title="Add new role"
                        ColorText="white"
                        BorderColor="#33A6CD"
                        BackgroundColor="#33A6CD"
                        borderRadius="25px"
                        handleOnclick={toggleAdd}
                        pending={false}
                      />
                    </div>
                  </div>
                  <DataTable
                    fixedHeader
                    fixedHeaderScrollHeight="300px"
                    columns={columns}
                    responsive
                    data={data}
                    pagination
                  />
                </CardBody>
              </Card>
            </div>
          </div>
        </Col>
      </Row>
      <OffCanvas
        isOffcanvasOpen={isAddRoles}
        title="Add Role"
        body={
          <>
            <div className="w-100">
              <form
                noValidate
                autoComplete="off"
                data-testid="form"
                onSubmit={handleAddRole}
              >
                {/* <div className="w-100">
                  <label>
                    Role name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={inputs?.name}
                    placeholder="Enter Role name"
                    onChange={handleChange}
                    style={{ borderRadius: 7 }}
                    className="mb-3 form-control fromInput"
                    required
                  />
                  
                </div> */}

                <div className="mt-2">
                  <label>
                    Role Name <span className="text-danger">*</span>
                  </label>
                  <select
                    className="px-2 form-control"
                    name="name"
                    onChange={handleChange}
                  >
                    <option>Select...</option>
                    <option value="Learner">Learner</option>
                    <option value="Intern">Intern</option>
                    <option value="Employee">Employee</option>
                    <option value="Manager">Manager</option>
                  </select>
                </div>
                <div className="mt-2">
                  <label>
                    Review Period <span className="text-danger">*</span>
                  </label>
                  <select
                    className="px-2 form-control"
                    name="period"
                    onChange={handleChange}
                  >
                    <option>Select...</option>
                    <option value="Monthly">Monthly</option>
                    <option value="3-Months">Every 3 Months</option>
                    <option value="4-Months">Every 4 Months</option>
                    <option value="6-Months">Every 6 Months</option>
                  </select>
                </div>
                <div className="w-100 mt-3">
                  <label>Level</label>
                  <span className="mx-1 text-dark">
                    {(() => {
                      switch (inputs?.name) {
                        case "Learner":
                        case "Intern":
                        case "Employee":
                          return 3;
                        case "Manager":
                          return 2;
                        default:
                          return null;
                      }
                    })()}
                  </span>
                </div>

                <div className="w-100 mt-5 d-flex justify-content-center">
                  <ButtonSubmit
                    Title="Create"
                    ColorText="white"
                    BorderColor="#33A6CD"
                    BackgroundColor="#33A6CD"
                    borderRadius="25px"
                    handleOnclick={handleAddRole}
                    pending={addMutation?.isLoading}
                  />
                </div>
              </form>
            </div>
          </>
        }
        toggleSaveChanges={() => setIsAddRoles(!isAddRoles)}
        directionOffCanvas="end"
        widthOffCanvas={400}
      />

      <Modal
        isOpen={isDeleteModal}
        toggle={() => setIsDeleteModal(!isDeleteModal)}
      >
        <ModalHeader toggle={() => setIsDeleteModal(!isDeleteModal)}>
          Delete Role
        </ModalHeader>
        <ModalBody>
          <p className="">
            To Delete this role enter{" "}
            <span style={{ fontWeight: "600" }}>{roleName}</span>
          </p>
          <form
            noValidate
            autoComplete="off"
            data-testid="form"
            onSubmit={handleDeleteRole}
          >
            <div className="w-100">
              <label>
                Role Name <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="name"
                value={inputsDelete?.name}
                onChange={handleDeleteChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control fromInput border-dark"
                required
              />
            </div>
            <div className="w-100 d-flex justify-content-between">
              <ButtonSubmit
                Title="Cancel"
                ColorText="white"
                BorderColor="#74788d"
                BackgroundColor="#74788d"
                borderRadius="25px"
                handleOnclick={toggleDeleteModal}
                pending={false}
              />
              {roleName === inputsDelete?.name ? (
                <ButtonSubmit
                  Title="Delete"
                  ColorText="white"
                  BorderColor="#ff0000"
                  BackgroundColor="#ff0000"
                  borderRadius="25px"
                  handleOnclick={handleDeleteRole}
                  pending={deleteMutation?.isLoading}
                />
              ) : (
                <ButtonSubmit
                  Title="Delete"
                  ColorText="white"
                  BorderColor="#74788d"
                  BackgroundColor="#74788d"
                  borderRadius="25px"
                  handleOnclick={() => {}}
                  pending={false}
                />
              )}
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Roles;
