import React from "react";
import { Bar } from "react-chartjs-2";

import "chart.js/auto";
import { Chart, CategoryScale } from "chart.js";
Chart.register(CategoryScale);

const data = {
  labels: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  datasets: [
    {
      label: "Review",
      data: [46, 57, 59, 54, 62, 58, 64, 60, 66, 77, 40, 80],
      backgroundColor: "#33A6CD",
      barThickness: 17,
    },
  ],
};

const options = {
  responsive: true,
  maintainAspectRatio: true,
  scales: {
    x: {
      grid: {
        display: false, // Removes vertical grid lines
      },
      ticks: {
        color: "#686868",
      },
      border: {
        display: false, // Removes the vertical axis line
      },
    },
    y: {
      grid: {
        display: false, // Removes horizontal grid lines
      },
      ticks: {
        color: "#7b919e",
      },
      border: {
        display: false, // Removes the horizontal axis line
      },
    },
  },
  plugins: {
    legend: {
      display: false, // Hides the legend
    },
  },
};

const BarChart = () => {
  return (
    <React.Fragment>
      <Bar width={537} height={268} data={data} options={options} />
    </React.Fragment>
  );
};

export default BarChart;
