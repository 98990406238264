import React, { useContext } from "react";
import CryptoJS from "crypto-js";
import { UserSubscriptionContext } from "../../../helpers/UserSubscriptionContext";
import ButtonGradient from "../../Common/Buttons/ButtonGradient";
import { encryptDataInOneLine } from "../../../lib/utils/encryptDataInOneLine";
import { generateUserKey } from "../../../lib/utils/generateUserKey";

function generateSignature(data, passPhrase = null) {
  let pfOutput = "";
  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      if (data[key] !== "") {
        pfOutput += `${key}=${encodeURIComponent(data[key].trim()).replace(
          /%20/g,
          "+"
        )}&`;
      }
    }
  }

  let getString = pfOutput.slice(0, -1);
  if (passPhrase !== null) {
    getString += `&passphrase=${encodeURIComponent(passPhrase.trim()).replace(
      /%20/g,
      "+"
    )}`;
  }

  return CryptoJS.MD5(getString).toString(CryptoJS.enc.Hex);
}

const SubsriptionDetails = ({ subscription }) => {
  const { customerDetails } = useContext(UserSubscriptionContext);

  const handlePayNow = () => {
    const encodedNameFirst = encodeURIComponent(customerDetails?.name_first);
    const encodedNameLast = encodeURIComponent(customerDetails?.name_last);
    const encodedEmail = encodeURIComponent(customerDetails?.email_address);
    const encodedPhone = encodeURIComponent(customerDetails?.phoneNumber);
    const randomChars = generateUserKey(10);
    const userID = `${customerDetails?.name_first}${customerDetails?.name_last}${randomChars}`;
    const queryString = `name_first=${encodedNameFirst}&name_last=${encodedNameLast}&email=${encodedEmail}&phone=${encodedPhone}&userKey=${userID}`;

    const secretKey = "14102024@Kago";
    const encryptedQueryString = encryptDataInOneLine(queryString, secretKey);

    const returnUrl = `https://hc.kago.digital/register?data=${encryptedQueryString}`;

    // const returnUrl = `https://hc.kago.digital/login/register?name_first=${encodedNameFirst}&name_last=${encodedNameLast}&email=${encodedEmail}&phone=${encodedPhone}`;

    // REACT_APP_EncryptKey;
    //put this back
    // merchant_id: process.env.REACT_APP_MechantID,
    //   merchant_key: process.env.REACT_APP_MechantKey,

    const myData = {
      merchant_id: "10034886",
      merchant_key: "hnsqkza5g704p",
      return_url: returnUrl,
      cancel_url: `https://hc.kago.digital/cancellation`,
      notify_url: `https://hc.kago.digital/notify`,
      name_first: customerDetails.name_first,
      name_last: customerDetails.name_last,
      email_address: customerDetails.email_address,
      m_payment_id: "1234",
      amount: `${subscription?.price}`,
      item_name: subscription?.packageName,
    };

    //  const myPassphrase = process.env.REACT_APP_PassPhrase;
    const myPassphrase = "Kago-testing-environemnt";
    myData.signature = generateSignature(myData, myPassphrase);

    const form = document.createElement("form");
    // form.action = process.env.REACT_APP_PayFastUrl;
    form.action = "https://sandbox.payfast.co.za/eng/process";
    form.method = "post";
    form.id = "payment-form";

    for (const [key, value] of Object.entries(myData)) {
      if (value !== "") {
        const hiddenInput = document.createElement("input");
        hiddenInput.type = "hidden";
        hiddenInput.name = key;
        hiddenInput.value = value.trim();
        form.appendChild(hiddenInput);
      }
    }

    const checkoutContainer = document.getElementById("checkout-container");

    // Check if checkoutContainer exists before appending the form
    if (checkoutContainer) {
      checkoutContainer.appendChild(form);
      form.submit();
    } else {
      console.error("Checkout container not found.");
    }
  };

  return (
    <div className="checkout" id="checkout-container">
      <div className="order"></div>
      <div className="h4">Subscription</div>
      <div
        className="w-100 mt-2"
        style={{ fontSize: 15, fontWeight: "bolder" }}
      >
        {subscription?.packageName}
      </div>
      <div
        className="mt-2 mb-2 text-primary"
        style={{ fontSize: 22, fontWeight: "bolder" }}
      >
        R {subscription?.price} / {subscription?.packageDuration}
      </div>
      {subscription?.ticketTerms && (
        <>
          <div style={{ fontSize: 18, fontWeight: "bold" }}>Note</div>
          <div style={{ fontSize: 13 }}>{subscription?.ticketTerms}</div>
        </>
      )}
      {customerDetails.name_first &&
        customerDetails.name_last &&
        customerDetails.phoneNumber && (
          <ButtonGradient
            type="button"
            Title="Pay Now"
            leftColor="#f7983c"
            rightColor="#ff7c6e"
            ColorText="white"
            BorderColor="transparent"
            borderRadius={50}
            handleOnclick={handlePayNow}
          />
        )}
    </div>
  );
};

export default SubsriptionDetails;
