const AdminData = [
  {
    label: "Dashboard",
    icon: "mdi mdi-home-variant-outline",
    url: "/dashboard",
    issubMenubadge: true,
    // bgcolor: "bg-primary",
    // badgeValue: "3",
  },
  {
    label: "Employee Review",
    icon: "mdi mdi-pencil-box-outline",
    url: "/employee-review",
    issubMenubadge: true,
  },
  // {
  //   label: "Employees",
  //   icon: "mdi mdi-account-multiple-outline",
  //   url: "/employees",
  //   issubMenubadge: true,
  // },
  // {
  //   label: "Form",
  //   icon: "mdi mdi-pencil-box-outline",
  //   url: "/review-form",
  //   issubMenubadge: true,
  //   bgcolor: "bg-primary",
  //   badgeValue: "3",
  // },
  // {
  //   label: "Charts",
  //   icon: "mdi mdi-chart-line",
  //   url: "/charts",
  //   issubMenubadge: true,
  // },
  // {
  //   label: "Talent Map",
  //   icon: "mdi mdi-auto-fix",
  //   url: "/#",
  //   issubMenubadge: true,
  // },
];
export default AdminData;
