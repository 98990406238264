import React from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Skeleton } from "@mui/material";

const LoaderUserProfile = () => (
  <div className="page-content" style={{ background: "#F2F2F2" }}>
    <Container fluid>
      {/* Breadcrumb Skeleton */}
      <Skeleton variant="text" width="30%" height={30} sx={{ mb: 2 }} />

      <Row>
        <Col md={2}></Col>
        <Col md={8}>
          {/* Profile Details Card Skeleton */}
          <Card>
            <CardBody className="w-100 p-4">
              <Skeleton
                variant="circular"
                width={80}
                height={80}
                sx={{ mb: 2 }}
              />
              <Skeleton variant="text" width="60%" height={30} />
              <Skeleton variant="text" width="40%" height={25} sx={{ mb: 3 }} />
              <Skeleton variant="rectangular" width="100%" height={150} />
            </CardBody>
          </Card>

          {/* User Personal Info Card Skeleton */}
          <Card>
            <CardBody className="w-100 p-4">
              <Skeleton variant="text" width="40%" height={30} sx={{ mb: 2 }} />
              <Skeleton variant="rectangular" width="100%" height={100} />
            </CardBody>
          </Card>

          {/* Update Password Card Skeleton */}
          <Card>
            <CardBody className="w-100 p-4">
              <Skeleton variant="text" width="40%" height={30} sx={{ mb: 2 }} />
              <Skeleton variant="rectangular" width="100%" height={80} />
            </CardBody>
          </Card>

          {/* Delete Account Card Skeleton */}
          <Card>
            <CardBody className="w-100 p-4">
              <Skeleton variant="text" width="40%" height={30} sx={{ mb: 2 }} />
              <Skeleton variant="rectangular" width="100%" height={60} />
            </CardBody>
          </Card>
        </Col>
        <Col md={2}></Col>
      </Row>
    </Container>
  </div>
);

export default LoaderUserProfile;
