import React, { useState, useEffect } from "react";
import usePost from "../../../lib/Hooks/usePost";
import { Row, Col } from "reactstrap";
import ButtonSubmit from "../../Common/Buttons/ButtonSubmit";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

function UserPersonalInfo({ userData, token }) {
  const { execute, pending, data, status } = usePost();
  const [inputs, setInputs] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    const Method = "PATCH",
      url = `users/updateMe`,
      isJSON = true;
    var formdata = new FormData();
    Object.entries(inputs).forEach(([key, value]) => {
      if (value) formdata.append(key, value);
    });

    execute(
      url,
      formdata,
      Method,
      "Profile successfully updated",
      token,
      isJSON
    );
  };

  useEffect(() => {
    if (status === "success") {
      const user = data?.data;
      if (user) {
        localStorage.clear();
        localStorage.setItem("authHC", JSON.stringify(user));
      }
    }
    if (userData) {
      const { firstName, lastName, email, phoneNumber } = userData;
      setInputs({
        firstName,
        lastName,
        email,
        phoneNumber,
      });
    }
  }, [status, userData]);

  // console.log("inputs..", JSON.stringify(inputs, null, 2));
  return (
    <div className="w-100 d-flex justify-content-center mt-3">
      <form className="form-create-event" onSubmit={handleSubmitForm}>
        <Row>
          <Col md={6}>
            <label className="event__input__form__Creation">First name </label>
            <input
              type="text"
              name="firstName"
              className="mb-3 form-control fromInput w-100"
              value={inputs?.firstName}
              onChange={handleChange}
              required
            />
          </Col>
          <Col md={6}>
            <label className="event__input__form__Creation">Last name</label>
            <input
              type="text"
              name="lastName"
              className="mb-3 form-control fromInput w-100"
              value={inputs?.lastName}
              onChange={handleChange}
              required
            />
          </Col>
          <Col md={6}>
            <label className="event__input__form__Creation">Email</label>
            <input
              type="email"
              name="email"
              className="mb-3 form-control fromInput w-100"
              value={inputs?.email}
              onChange={handleChange}
              disabled
            />
          </Col>
          <Col md={6}>
            <label>Phone Number</label>
            <div className="phone-input-container">
              <PhoneInput
                international
                countryCallingCodeEditable={false}
                defaultCountry="ZA"
                value={inputs.phoneNumber ? inputs.phoneNumber : null}
                onChange={(value) =>
                  setInputs({ ...inputs, phoneNumber: value })
                }
                className="custom-phone-input"
              />
            </div>
          </Col>
          <Col md={12}>
            <label>Bio </label>
            <textarea
              name="bio"
              value={inputs?.bio}
              onChange={handleChange}
              className="w-100 p-2 rounded-3 form-control fromInput"
              rows="4"
              required
            />
          </Col>

          <div className="d-flex justify-content-center align-item-center mt-3">
            <ButtonSubmit
              Title="Update"
              BackgroundColor="#33A6CD"
              ColorText="white"
              BorderColor="#33A6CD"
              borderRadius={20}
              handleOnclick={handleSubmitForm}
              pending={pending}
            />
          </div>
        </Row>
      </form>
    </div>
  );
}

export default UserPersonalInfo;
