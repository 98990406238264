import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";

const PasswordForm = ({ inputs, setInputs, setSubmitEnabled }) => {
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  useEffect(() => {
    if (
      inputs.password &&
      inputs.passwordConfirm &&
      inputs.password === inputs.passwordConfirm
    ) {
      setSubmitEnabled(true);
    } else {
      setSubmitEnabled(false);
    }
  }, [inputs, setSubmitEnabled]);

  return (
    <>
      <Row className="w-100">
        <Col md={6}>
          <label>
            Password <span className="text-danger">*</span>
          </label>
          <input
            type="password"
            name="password"
            value={inputs.password}
            placeholder="Enter password"
            onChange={handleChange}
            style={{ borderRadius: 7 }}
            className="mb-3 form-control fromInput"
            required
          />
        </Col>
        <Col md={6}>
          <label>
            Confirm password <span className="text-danger">*</span>
          </label>
          <input
            type="password"
            name="passwordConfirm"
            value={inputs.passwordConfirm}
            placeholder="Enter confirm password"
            onChange={handleChange}
            style={{ borderRadius: 7 }}
            className="mb-3 form-control fromInput"
            required
          />
        </Col>
      </Row>
    </>
  );
};

export default PasswordForm;
