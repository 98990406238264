import React, { useState, useRef, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import useCustomMutation from "../../../../lib/Hooks/useCustomMutation";
import "./assessEmployee.css";
import { MdDeleteForever } from "react-icons/md";
import { IoMdSave } from "react-icons/io";
import { IoImageOutline } from "react-icons/io5";

// Function to extract data for each section
const getSectionData = (sectionName, reviewDetails) => {
  let sectionData = reviewDetails?.assessment?.employee?.data;

  // Check if the section is within "Functional Skills"
  const functionalSkills = sectionData?.find(
    (item) => item.name === "Functional Skills"
  );

  const criteria =
    functionalSkills?.criteria?.find(
      (criterion) => criterion?.name === sectionName
    ) ||
    sectionData?.find((section) => section?.name === sectionName)?.criteria[0];

  return (
    criteria?.evaluations?.map((evaluation) => ({
      attribute: evaluation?.attribute,
      score: evaluation?.score,
    })) || []
  );
};

// Question Component
const Question = ({
  questionText,
  handleSelect,
  selectedValue,
  sectionKey,
  index,
  choices = [],
  preselectedValue,
  employeeName,
  userFullName,
}) => {
  if (!Array.isArray(choices)) {
    console.error(
      `Expected choices to be an array but got ${typeof choices}`,
      choices
    );
    return null;
  }

  return (
    <div className="question">
      <p>{questionText}</p>
      <div className="choices-container">
        <div className="employee-choice">
          <div>
            {choices.map((choice, idx) => (
              <button
                key={idx}
                className={`choice-button employee-choice-button ${
                  preselectedValue === choice ? "active" : ""
                }`}
                disabled
              >
                {choice}
              </button>
            ))}
          </div>
          <span className="employee-name">{employeeName}</span>
        </div>

        <div className="manager-choice">
          <div>
            {choices.map((choice, idx) => (
              <button
                key={idx}
                className={`choice-button ${
                  selectedValue === choice ? "active" : ""
                }`}
                onClick={() => handleSelect(sectionKey, index, choice)}
              >
                {choice}
              </button>
            ))}
          </div>
          <span className="manager-name">{userFullName}</span>
        </div>
      </div>
    </div>
  );
};

// OverallProgress Component
const OverallProgress = ({
  overallData,
  handleSelect,
  selectedValues,
  employeeName,
  userFullName,
}) => {
  const questions = [
    "Employee performance and learning is unsatisfactory and is failing to improve at a satisfactory rate",
    "Employee performance and learning is acceptable and is improving at a satisfactory rate",
    "Employee has successfully demonstrated outstanding overall performance",
  ];

  return (
    <div className="section">
      <h3>Overall Progress</h3>
      {questions.map((questionText, index) => (
        <div key={index} className="question">
          <p>{questionText}</p>
          <div className="choices-container">
            <div className="employee-choice">
              <button
                className={`choice-button employee-choice-button ${
                  overallData[index]?.value === "Yes" ? "active" : ""
                }`}
                disabled
              >
                Yes
              </button>
              <button
                className={`choice-button employee-choice-button ${
                  overallData[index]?.value === "No" ? "active" : ""
                }`}
                disabled
              >
                No
              </button>
              <span className="employee-name">{employeeName}</span>
            </div>
            <div className="manager-choice">
              <button
                className={`choice-button ${
                  selectedValues[index] === "Yes" ? "active" : ""
                }`}
                onClick={() => handleSelect("overallProgress", index, "Yes")}
              >
                Yes
              </button>
              <button
                className={`choice-button ${
                  selectedValues[index] === "No" ? "active" : ""
                }`}
                onClick={() => handleSelect("overallProgress", index, "No")}
              >
                No
              </button>
              <span className="manager-name">{userFullName}</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

// Section Component
const Section = ({
  sectionTitle,
  sectionKey,
  questions,
  handleSelect,
  answers,
  choicesPerQuestion = [],
  preselectedAnswers = [],
  employeeName,
  userFullName,
}) => {
  return (
    <div className="section">
      <h3>
        {sectionTitle === "Quality of Work"
          ? "Quality of Work (Out of 15 Marks)"
          : sectionTitle === "Work Habits"
          ? "Quality of Work (Out of 20 Marks)"
          : sectionTitle === "Job Knowledge"
          ? "Job Knowledge (Out of 15 Marks)"
          : sectionTitle === "Interpersonal Skills"
          ? "Interpersonal Skills (Out of 25 Marks)"
          : sectionTitle === "Leadership Skills"
          ? "Leadership Skills (Out of 25 Marks)"
          : sectionTitle}
      </h3>

      {questions.map((question, index) => {
        return (
          <Question
            key={index}
            questionText={question.attribute}
            handleSelect={handleSelect}
            selectedValue={answers[sectionKey] && answers[sectionKey][index]}
            sectionKey={sectionKey}
            index={index}
            choices={
              question.attribute === "Work motivation."
                ? [1, 2, 3, 4, 5]
                : choicesPerQuestion[index] || [1, 2, 3, 4, 5]
            }
            preselectedValue={preselectedAnswers[index]?.score || null}
            employeeName={employeeName}
            userFullName={userFullName}
          />
        );
      })}
    </div>
  );
};

// Main AssessEmployee Component
const AssessEmployee = ({
  reviewDetails,
  setIsCompleteEvatuation,
  userFullName,
  organizationID,
  token,
}) => {
  const [answers, setAnswers] = useState({
    qualityOfWork: Array(3).fill(0),
    workHabits: Array(4).fill(0),
    jobKnowledge: Array(3).fill(0),
    interpersonalSkills: Array(5).fill(0),
    leadershipSkills: Array(3).fill(0),
    overallProgress: Array(3).fill(null),
  });
  const [recommendation, setRecommendation] = useState("");

  const [currentStep, setCurrentStep] = useState(1);
  const key = ["DepartmentReviews", organizationID];
  const Mutation = useCustomMutation(key);

  // signature and image
  const [image, setImage] = useState(null);
  const sigPadRef = useRef(null);
  const fileInputRef = useRef(null);

  const clearSignature = () => {
    if (sigPadRef.current) {
      sigPadRef.current.clear();
    }
    setImage(null);
  };

  const saveSignature = () => {
    if (image) {
    } else if (sigPadRef.current && !sigPadRef.current.isEmpty()) {
      setImage(sigPadRef.current.getTrimmedCanvas().toDataURL("image/png"));
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => setImage(e.target.result);
      reader.readAsDataURL(file);
    }
  };

  const handleSelect = (sectionKey, questionIndex, value) => {
    const standardizedKey =
      sectionKey.charAt(0).toLowerCase() + sectionKey.slice(1);

    setAnswers((prev) => {
      const updatedSection = [...(prev[standardizedKey] || [])];
      updatedSection[questionIndex] = value;
      const newAnswers = { ...prev, [standardizedKey]: updatedSection };
      return newAnswers;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const filteredAnswers = Object?.entries(answers)?.reduce(
      (acc, [key, valueArray]) => {
        if (
          Array?.isArray(valueArray) &&
          valueArray?.some((val) => val !== 0)
        ) {
          acc[key] = valueArray;
        }
        return acc;
      }
    );

    const Method = "PATCH",
      url = `assessments/${reviewDetails?._id}/review`;

    const raw = JSON.stringify({
      manager: {
        data: [
          {
            name: "Functional Skills",
            criteria: [
              {
                name: "Quality of Work",
                evaluations: [
                  {
                    attribute: "Accuracy, neatness and timeliness of work",
                    score: parseInt(filteredAnswers?.qualityofwork[0]),
                  },
                  {
                    attribute:
                      "Adherence to duties and procedures in Job Desctiption and work instructions",
                    score: parseInt(filteredAnswers?.qualityofwork[1]),
                  },
                  {
                    attribute:
                      "Synchronization with organization/functional goals",
                    score: parseInt(filteredAnswers?.qualityofwork[2]),
                  },
                ],
              },
              {
                name: "Work Habits",
                evaluations: [
                  {
                    attribute: "Punctuality to workplace",
                    score: parseInt(filteredAnswers?.workhabits[0]),
                  },
                  {
                    attribute: "Attendance",
                    score: parseInt(filteredAnswers?.workhabits[1]),
                  },
                  {
                    attribute:
                      "Does the employee stay busy, look for things to do, takes initiatives at workplace",
                    score: parseInt(filteredAnswers?.workhabits[2]),
                  },
                  {
                    attribute: "Submits reports on time and meets deadlines",
                    score: parseInt(filteredAnswers?.workhabits[3]),
                  },
                ],
              },
              {
                name: "Job Knowledge",
                evaluations: [
                  {
                    attribute:
                      "Skill and ability to perform job satisfactorily",
                    score: parseInt(filteredAnswers?.jobknowledge[0]),
                  },
                  {
                    attribute: "Shown interest in learning and improving",
                    score: parseInt(filteredAnswers?.jobknowledge[1]),
                  },
                  {
                    attribute: "Problem solving ability",
                    score: parseInt(filteredAnswers?.jobknowledge[2]),
                  },
                ],
              },
            ],
          },
          {
            name: "Interpersonal Skills",
            criteria: [
              {
                name: "Interpersonal relations/behaviour",
                evaluations: [
                  {
                    attribute: "Responds and contributes to team efforts",
                    score: parseInt(filteredAnswers?.interpersonalskills[0]),
                  },
                  {
                    attribute:
                      "Responds positively to suggestions and instructions and criticism",
                    score: parseInt(filteredAnswers?.interpersonalskills[1]),
                  },
                  {
                    attribute: "Keeps supervisor informed of all details",
                    score: parseInt(filteredAnswers?.interpersonalskills[2]),
                  },
                  {
                    attribute: "Adapts well to changing circumstances",
                    score: parseInt(filteredAnswers?.interpersonalskills[3]),
                  },
                  {
                    attribute: "Seeks feedback to improve",
                    score: parseInt(filteredAnswers?.interpersonalskills[4]),
                  },
                ],
              },
            ],
          },
          {
            name: "Leadership Skills",
            criteria: [
              {
                name: "Leadership",
                evaluations: [
                  {
                    attribute:
                      "Aspirant to climb up the ladder, accepts challenges, new responsibilities and roles",
                    score: parseInt(filteredAnswers?.leadershipskills[0]),
                  },
                  {
                    attribute:
                      "Innovative thinking - contribution to organizations and functions and personal growth",
                    score: parseInt(filteredAnswers?.leadershipskills[1]),
                  },
                  {
                    attribute: "Work motivation",
                    score: parseInt(filteredAnswers?.leadershipskills[2]),
                  },
                ],
              },
            ],
          },
        ],
        overall: [
          {
            attribute:
              "Employee performance and learning is unsatisfactory and is failing to improve at a satisfactory rate",
            value:
              filteredAnswers.overallProgress &&
              filteredAnswers.overallProgress[0] === "Yes"
                ? "Yes"
                : "No",
          },
          {
            attribute:
              "Employee performance and learning is acceptable and is improving at a satisfactory rate",
            value:
              filteredAnswers.overallProgress &&
              filteredAnswers.overallProgress[1] === "Yes"
                ? "Yes"
                : "No",
          },
          {
            attribute:
              "Employee has successfully demonstrated outstanding overall performance",
            value:
              filteredAnswers.overallProgress &&
              filteredAnswers.overallProgress[2] === "Yes"
                ? "Yes"
                : "No",
          },
        ],
        recommendation: recommendation,
        comment: "",
      },
      status: "Not Signed",
      managerSignature: image,
    });

    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Review successfully submitted",
      token: token,
    };
    Mutation.mutate(data);
  };

  useEffect(() => {
    if (Mutation?.isSuccess) {
      setAnswers({
        qualityOfWork: Array(3).fill(null),
        workHabits: Array(4).fill(null),
        jobKnowledge: Array(3).fill(null),
        interpersonalSkills: Array(5).fill(null),
        leadershipSkills: Array(3).fill(null),
        overallProgress: Array(3).fill(null),
      });
      setIsCompleteEvatuation(false);
    }
  }, [Mutation?.isSuccess]);

  // console.log("Review....:", JSON.stringify(reviewDetails, null, 2));

  return (
    <div>
      <div className="evaluation-form">
        {[
          { title: "Quality of Work", choices: [1, 2, 3, 4, 5] },
          { title: "Work Habits", choices: [1, 2, 3, 4, 5] },
          { title: "Job Knowledge", choices: [1, 2, 3, 4, 5] },
          { title: "Interpersonal Skills", choices: [1, 2, 3, 4, 5] },
          {
            title: "Leadership Skills",
            choices: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
          },
        ].map(
          (section, index) =>
            currentStep === index + 1 && (
              <Section
                key={section?.title}
                sectionTitle={section?.title}
                sectionKey={section?.title?.toLowerCase().replace(/\s+/g, "")}
                questions={getSectionData(section.title, reviewDetails)}
                handleSelect={handleSelect}
                answers={answers}
                choicesPerQuestion={Array(3).fill(section.choices)}
                preselectedAnswers={getSectionData(
                  section.title,
                  reviewDetails
                )}
                employeeName={
                  reviewDetails?.userId
                    ? `${reviewDetails?.userId?.firstName} ${reviewDetails?.userId?.lastName}`
                    : "Employee"
                }
                userFullName={userFullName}
              />
            )
        )}

        {currentStep === 6 && (
          <OverallProgress
            overallData={reviewDetails?.assessment?.employee?.overall}
            handleSelect={(sectionKey, questionIndex, value) => {
              setAnswers((prev) => {
                const updatedSection = [...(prev[sectionKey] || [])];
                updatedSection[questionIndex] = value;
                return { ...prev, [sectionKey]: updatedSection };
              });
            }}
            selectedValues={answers.overallProgress}
            employeeName={
              reviewDetails?.userId
                ? `${reviewDetails?.userId?.firstName} ${reviewDetails?.userId?.lastName}`
                : "Employee"
            }
            userFullName={userFullName}
          />
        )}
        {currentStep === 7 && (
          <>
            <h4>Final Comment</h4>
            <div className="w-100">
              <div className="mb-2">Employee Comment:</div>
              <textarea
                readOnly
                value={reviewDetails?.assessment?.employee?.comment}
                rows={4}
                style={{
                  width: "100%",
                  height: 150,
                  borderRadius: 10,
                  padding: 10,
                  color: "#333333",
                }}
              />
            </div>
            <div className="w-100">
              <div className="mb-2 mt-3">Manager Recommendation:</div>
              <textarea
                value={recommendation}
                placeholder="write here..."
                onChange={(e) => setRecommendation(e.target.value)}
                rows={4}
                style={{
                  width: "100%",
                  height: 150,
                  borderRadius: 10,
                  padding: 10,
                }}
              />
            </div>
          </>
        )}
        {currentStep === 8 && (
          <>
            <h4>Manager signature</h4>
            <div className="signature-container">
              {image ? (
                <img
                  src={image}
                  alt="Signature Preview"
                  className="signature-preview"
                />
              ) : (
                <SignatureCanvas
                  ref={sigPadRef}
                  penColor="#33a6cd"
                  canvasProps={{
                    className: "sigCanvas",
                    width: 500,
                    height: 200,
                  }}
                />
              )}
              <div className="signature-btn-container">
                <MdDeleteForever
                  size={30}
                  color="#ff0000"
                  onClick={clearSignature}
                />
                <br />{" "}
                <div className="mt-2">
                  <IoMdSave size={26} color="#1A8F24" onClick={saveSignature} />
                </div>
              </div>
            </div>
            <div className="signature-upload-photo">
              <button
                onClick={() => fileInputRef?.current?.click()}
                className="signature-upload-photo-btn"
              >
                <IoImageOutline size={20} color="#33a6cd" />
                <span className="mx-2" style={{ textDecoration: "underline" }}>
                  Upload image
                </span>
              </button>
              <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
              />
            </div>
          </>
        )}

        <div className="form-navigation-assignment">
          {currentStep > 1 && (
            <button
              className="back-button"
              onClick={() => setCurrentStep(currentStep - 1)}
            >
              Back
            </button>
          )}
          {currentStep < 8 ? (
            <button
              className="next-button"
              onClick={() => setCurrentStep(currentStep + 1)}
            >
              Next
            </button>
          ) : (
            <>
              {image ? (
                <button className="submit-button" onClick={handleSubmit}>
                  {Mutation?.isLoading ? "...Loading" : "Submit"}
                </button>
              ) : (
                <button className="back-button" onClick={() => {}}>
                  Submit
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssessEmployee;
