import React from "react";
import useCustomFetchQuery from "../../../lib/Hooks/useCustomFecthQuery";
import MyReviewTable from "../../../components/Pages/Dashboard/Employee/MyReviewTable";
import MonthlyAnalysisis from "../../../components/Pages/Dashboard/Employee/MyMonthlyAnalysis";

const Employee = ({ userID, token, organizationID, departmentID }) => {
  // const {
  //   data: orgAnalytics,
  //   error,
  //   isLoading,
  // } = useCustomFetchQuery(
  //   ["OrgAnalytics", organizationID],
  //   `${process.env.REACT_APP_APIKEY}/reports/${organizationID}`,
  //   token,
  //   { enabled: !!organizationID }
  // );

  const {
    data: employeeAnalytics,
    error,
    isLoading,
  } = useCustomFetchQuery(
    ["EmployeeAnalytics", userID],
    `${process.env.REACT_APP_APIKEY}reports/${userID}/employee`,
    token,
    { enabled: !!userID }
  );

  return (
    <React.Fragment>
      <MonthlyAnalysisis
        userID={userID}
        token={token}
        employeeAnalytics={employeeAnalytics}
      />
      <MyReviewTable
        userID={userID}
        token={token}
        organizationID={organizationID}
        departmentID={departmentID}
      />
    </React.Fragment>
  );
};

export default Employee;
