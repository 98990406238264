import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import Logo from "../../../Common/Profile/Logo";
import ImagePlaceHolder from "../../../../assets/images/ImagePlaceHolder.png";

const AddCompany = ({ inputs, setInputs, setNextEnabled }) => {
  const [imageURL, setImageURL] = useState(ImagePlaceHolder);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  useEffect(() => {
    if (inputs.companyName && inputs.companySize) {
      setNextEnabled(true);
    } else {
      setNextEnabled(false);
    }
  }, [inputs, setNextEnabled]);

  return (
    <>
      <Row className="w-100">
        <Col md={12}>
          <div className="w-100 mt-2 mb-3 d-flex justify-content-center">
            logo size (200 X 400) pixels
          </div>
          <div className="w-100 mb-3 d-flex justify-content-center">
            <Logo imageURL={imageURL} setImageURL={setImageURL} />
          </div>
        </Col>
        <Col md={6}>
          <label>
            Company Name <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            name="companyName"
            value={inputs?.companyName}
            placeholder="Enter Company name"
            onChange={handleChange}
            style={{ borderRadius: 7 }}
            className="mb-3 form-control fromInput"
            required
          />
        </Col>
        <Col md={6}>
          <label>
            Company Size <span className="text-danger">*</span>
          </label>
          <input
            type="number"
            name="companySize"
            value={inputs?.companySize}
            placeholder="How many employees ?"
            onChange={handleChange}
            style={{ borderRadius: 7 }}
            className="mb-3 form-control fromInput"
            required
          />
        </Col>
        {/* <Col md={4}>
          <label>
            Company Sector <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            name="companySector"
            value={inputs.companySector}
            placeholder="Enter Company sector"
            onChange={handleChange}
            style={{ borderRadius: 7 }}
            className="mb-3 form-control fromInput"
            required
          />
        </Col> */}
      </Row>
    </>
  );
};

export default AddCompany;
