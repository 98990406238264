import React from "react";
import ReactApexChart from "react-apexcharts";

const gradientColors = [
  "#ff9a9e",
  "#a18cd1",
  "#f093fb",
  " #4facfe ",
  "#43e97b",
  "#fa709a ",
  "#30cfd0",
  "#a1c4fd",
];

const BarChart = ({ deptOverall }) => {
  // console.log("deptOverall...", JSON.stringify(deptOverall, null, 2));
  if (!deptOverall || !Array.isArray(deptOverall)) {
    return <div></div>;
  }
  const labels = deptOverall?.map((item) => item?.department);
  const series = deptOverall?.map((item) => Math?.round(Number(item?.share)));
  const colors = Array?.from(
    { length: labels?.length },
    (_, i) => gradientColors[i % gradientColors?.length]
  );

  const data = {
    series: series,
    options: {
      labels: labels,
      colors: colors,
      chart: {
        type: "donut",
      },
      legend: {
        show: false,
      },
      plotOptions: {
        pie: {
          donut: {
            size: "75%",
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      responsive: [
        {
          breakpoint: 600,
          options: {
            chart: {
              height: 240,
            },
          },
        },
      ],
    },
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: "300px" }}>
          <ReactApexChart
            options={data?.options}
            series={data?.series}
            type="donut"
            height="320"
            className="apex-charts"
          />
        </div>
        <div style={{ marginLeft: "20px" }}>
          <ul style={{ listStyleType: "none", padding: 0 }}>
            {deptOverall?.map((item, index) => (
              <li key={item?.department} style={{ marginBottom: "10px" }}>
                <span
                  style={{
                    display: "inline-block",
                    width: "20px",
                    height: "20px",
                    backgroundColor: colors[index],
                    marginRight: "10px",
                    backgroundSize: "cover",
                  }}
                ></span>
                {item?.department}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BarChart;
