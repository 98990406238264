import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap";

// Redux imports
import { Link } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";

// Formik validation and Yup schema
import * as Yup from "yup";
import { useFormik } from "formik";

// Import logo
import logoKago from "../../../src/assets/images/kago-logo.png";

// ForgotPassword component definition
const ForgotPassword = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState(null);

  document.title = "Forgot Password | Kago Human Capital";

  // Formik setup for form validation and submission
  const validation = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email")
        .required("Please enter your email"),
    }),
    onSubmit: (values) => {
      setIsLoading(true);
      
      // API request integration
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        email: values.email,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `https://employee-evaluation-kago-e63baae4d822.herokuapp.com/api/v1/auth/forgotPassword`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setIsLoading(false);
          setResponseMessage(result.message || "Password reset email sent!");
        })
        .catch((error) => {
          setIsLoading(false);
          setResponseMessage("Error sending password reset email. Please try again.");
          console.error(error);
        });
    },
  });

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5" style={{ backgroundColor: "#f5f5f5" }}>
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                {/* Banner Section */}
                <div className="bg-primary" style={{ backgroundColor: "#33a6cd", padding: "40px 20px" }}>
                  <Row>
                    <Col xs={12}>
                      <div className="text-white p-4">
                        <h5 className="text-white">Forgot Your Password?</h5>
                        <p>Please enter your email address to receive a password reset link.</p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="d-flex justify-content-center">
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoKago}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {/* Response Message */}
                    {responseMessage && (
                      <Alert color={responseMessage.includes("Error") ? "danger" : "success"} style={{ marginTop: "13px" }}>
                        {responseMessage}
                      </Alert>
                    )}

                    {/* Form Section */}
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter your email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            <div>{validation.errors.email}</div>
                          </FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <button
                            className="btn btn-primary w-md"
                            type="submit"
                            style={{
                              backgroundColor: "#33a6cd",
                              borderColor: "#33a6cd",
                            }}
                          >
                            {isLoading ? "Sending..." : "Send Reset Link"}
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Remember your password?{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    Login here
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

// PropTypes for type-checking
ForgotPassword.propTypes = {
  history: PropTypes.object,
};

// Export the component with router props
export default withRouter(ForgotPassword);
