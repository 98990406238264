import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ownerRegistration.scss";
import usePost from "../../../lib/Hooks/usePost";
import ButtonSubmit from "../../Common/Buttons/ButtonSubmit";
import PersonalForm from "./PersonalForm";
import AddCompany from "../OrganizationSettings/AddCompany/AddCompany";
import PasswordForm from "./PasswordForm";
import { encryptDataInOneLine } from "../../../lib/utils/encryptDataInOneLine";

const AdminRegistration = () => {
  const subscription = JSON.parse(localStorage.getItem("subscriptionHC"));
  const Navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(1);
  const [inputs, setInputs] = useState({});
  const [uploadImg, setUploadImg] = useState(null);
  const [next, setNext] = useState(false);
  const [nextEnabled, setNextEnabled] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const { status, execute, pending, data } = usePost();

  // console.log("subscription....", subscription);
  // console.log("...data....", JSON.stringify(data?.data, null, 2));

  // Navigation between steps
  const handleNextStep = () =>
    currentStep < 3 && setCurrentStep(currentStep + 1);
  const handlePreviousStep = () =>
    currentStep > 1 && setCurrentStep(currentStep - 1);

  // Form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    const url = `auth/admin`;
    const raw = JSON.stringify({
      firstName: inputs.firstName,
      lastName: inputs.lastName,
      phoneNumber: inputs.phoneNumber,
      email: inputs.email,
      companyName: inputs.companyName,
      companySize: inputs.companySize,
      password: inputs.password,
      passwordConfirm: inputs.passwordConfirm,
    });
    execute(url, raw, "POST", "Successfully registered");
  };

  useEffect(() => {
    if (status) {
      Navigate(`/checkout?data=${data?.data}`);
    }
  }, [status]);

  return (
    <div className="organizer-general-container">
      <div className="multi-step-container">
        <div className="multi-step-sidebar" style={{ userSelect: "none" }}>
          {[1, 2, 3].map((step) => (
            <div
              key={step}
              className={`multi-step-step ${
                currentStep === step ? "multi-step-active" : ""
              }`}
              onClick={() => setCurrentStep(step)}
            >
              <div className="multi-step-circle">{step}</div>
              <div className="multi-step-info">
                <h4>STEP {step}</h4>
                <p>
                  {step === 1
                    ? "Personal Info"
                    : step === 2
                    ? "Company Profile"
                    : "Password"}
                </p>
              </div>
            </div>
          ))}
        </div>

        {/* Form content */}
        <div className="multi-step-form-container">
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            {currentStep === 1 && (
              <>
                <h2>Personal Info</h2>
                <p>Please provide your personal information.</p>
                <PersonalForm
                  inputs={inputs}
                  setInputs={setInputs}
                  setNext={setNext}
                />
              </>
            )}

            {currentStep === 2 && (
              <>
                <h2>Company Profile</h2>
                <AddCompany
                  uploadImg={uploadImg}
                  setUploadImg={setUploadImg}
                  inputs={inputs}
                  setInputs={setInputs}
                  setNextEnabled={setNextEnabled}
                />
              </>
            )}

            {currentStep === 3 && (
              <>
                <h2>Password</h2>
                <p>Please provide your password.</p>
                <PasswordForm
                  inputs={inputs}
                  setInputs={setInputs}
                  setSubmitEnabled={setSubmitEnabled}
                />
              </>
            )}

            {/* Navigation buttons */}
            <div className="multi-step-navigation">
              {currentStep > 1 && (
                <ButtonSubmit
                  Title="Previous"
                  BackgroundColor="#1d3f72"
                  ColorText="#fff"
                  BorderColor="#1d3f72"
                  borderRadius={20}
                  handleOnclick={handlePreviousStep}
                  pending={false}
                  width={100}
                  height={40}
                />
              )}

              {currentStep < 2 && next ? (
                <ButtonSubmit
                  Title="Next"
                  BackgroundColor="#1d3f72"
                  ColorText="#fff"
                  BorderColor="#1d3f72"
                  borderRadius={20}
                  handleOnclick={handleNextStep}
                  pending={false}
                />
              ) : null}

              {currentStep > 1 && currentStep < 3 && nextEnabled ? (
                <ButtonSubmit
                  Title="Next"
                  BackgroundColor="#1d3f72"
                  ColorText="#fff"
                  BorderColor="#1d3f72"
                  borderRadius={20}
                  handleOnclick={handleNextStep}
                  pending={false}
                />
              ) : null}

              {submitEnabled ? (
                <ButtonSubmit
                  Title="Submit"
                  BackgroundColor="#1d3f72"
                  ColorText="#fff"
                  BorderColor="#1d3f72"
                  borderRadius={20}
                  handleOnclick={handleSubmit}
                  pending={pending}
                />
              ) : null}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminRegistration;
