import React from "react";
import useCustomFetchQuery from "../../lib/Hooks/useCustomFecthQuery";
import EditCompany from "../../components/Pages/OrganizationSettings/EditCompany/EditCompany";
import Department from "../../components/Pages/OrganizationSettings/Department";
import Roles from "../../components/Pages/OrganizationSettings/Roles";
import LoaderOrganizationSettings from "../../components/Loader/LoaderOrganizationSettings";

const Index = () => {
  const obj = JSON.parse(localStorage.getItem("authHC"));
  const token = obj?.token;
  const organizationID = obj?.data?.data?.organizationId;

  const {
    data: organizationDetails,
    error,
    isLoading,
  } = useCustomFetchQuery(
    ["CompanyDetails", organizationID],
    `${process.env.REACT_APP_APIKEY}organizations/${organizationID}`,
    token,
    { enabled: !!organizationID }
  );

  if (isLoading) {
    return <LoaderOrganizationSettings />;
  }

  return (
    <React.Fragment>
      <div className="page-content w-100 d-flex justify-content-center">
        <div className="w-100" style={{ padding: 5 }}>
          <div className="w-100 mb-5">
            <EditCompany
              organizationID={organizationID}
              organizationDetails={organizationDetails}
              token={token}
            />
          </div>
          <div className="w-100 mt-4">
            <Department token={token} organizationID={organizationID} />
          </div>
          <div className="w-100 mt-5">
            <Roles
              token={token}
              organizationID={organizationID}
              roles={organizationDetails?.data?.roles}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Index;
