export const Container = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: 15,
  userSelect: "none",
};
export const EmplyeeContainerChart = {
  width: "100%",
  height: "340px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: 15,
  userSelect: "none",
};

export const Wrapper = {
  width: "100%",
  borderWidth: 2,
  borderColor: "#D9D9D9",
  borderRadius: 15,
  borderStyle: "solid",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  //   backgroundColor: "purple",
};

export const Title = {
  display: "flex",
  justifyContent: "center",
  fontSize: 14,
  fontWeight: "bolder",
};

export const SubTitle = {
  display: "flex",
  justifyContent: "center",
  fontSize: 29,
  fontWeight: "bolder",
};

export const employeeTblTitle = {
  width: "100%",
  display: "flex",
  padding: 5,
  justifyContent: "center",
  alignItems: "center",
  fontSize: 16,
  fontWeight: "bolder",
};

export const employeeTbl = {
  borderRadius: 10,
  padding: 5,
  borderWidth: 2,
  borderStyle: "solid",
  borderColor: "#D9D9D9",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
};
