import { useMutation, useQueryClient } from "@tanstack/react-query";
import post from "./post";
import { dangerMessage } from "../../components/Common/Toast/Toast";

function useCustomMutation(key) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (data) => {
      return post(
        data.endPoint,
        data.bodyContent,
        data.Method,
        data.displayMessage,
        data.token,
        data.isJSON
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(key);
    },
    onError: () => {
      dangerMessage("Oops! your internet is not working");
    },
  });

  return mutation;
}

export default useCustomMutation;
