import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ImCross } from "react-icons/im";

const VerifyPage = () => {
  document.title = "Kago Human Capital | Email Verification";
  const Navigate = useNavigate();
  let params = useParams();
  const userID = params.userID;
  const token = params.token;
  const [message, setMessage] = useState(null);

  const verifyRequest = () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APIKEY}auth/verify/${userID}/${token}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        if (res?.status === "success") {
          Navigate("/login");
        }
        if (res?.status === "fail") {
          setMessage(res?.message);
        }
      })
      .catch((error) => {});
  };
  useEffect(() => {
    if (token && userID) {
      verifyRequest();
    }
  }, [token]);
  return (
    <>
      {message && (
        <div className="message-page">
          <div className="message-card-content">
            <div className="message-card-icon bg-danger">
              <ImCross size={27} color="white" />
            </div>
            <h2 className="text-danger">verification Failed!</h2>
            <p>{message}</p>

            <button
              className="message-card-button bg-danger"
              onClick={() => {
                Navigate(`/pricing`);
              }}
            >
              OK
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default VerifyPage;
