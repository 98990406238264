import React, { useRef } from "react";
import { LiaUser } from "react-icons/lia";
import { FaEdit } from "react-icons/fa";

function EditProfilePicture({
  showImage,
  setShowImage,
  setUploadImg,
  iconSize,
  iconColor,
  imgHeight,
  imgWidth,
  imgBorder,
  bgColor,
}) {
  const fileInputRef = useRef(null);

  const handleUploadImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploadImg(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setShowImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="w-100 d-flex justify-content-center">
      <div
        style={{
          position: "relative",
          backgroundColor: bgColor || "#ededed",
          width: imgWidth,
          height: imgHeight,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: imgBorder,
          userSelect: "none",
        }}
      >
        {showImage ? (
          <img
            src={showImage}
            className="w-100 h-100"
            style={{ borderRadius: imgBorder }}
            alt="profile"
          />
        ) : (
          <LiaUser size={iconSize} color={iconColor} />
        )}

        {/* Edit Icon to trigger image upload */}
        <div
          onClick={handleIconClick}
          style={{
            position: "absolute",
            top: "35%",
            left: "35%",
            backgroundColor: "white",
            width: 30,
            height: 30,
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
            cursor: "pointer",
          }}
        >
          <FaEdit size={15} color="black" style={{ marginLeft: 9 }} />
        </div>
      </div>

      {/* Hidden file input for image upload */}
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleUploadImage}
        style={{ display: "none" }}
        accept="image/*"
      />
    </div>
  );
}

export default EditProfilePicture;
