import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Style.scss";

export const warningMessage = (message) => {
  toast.warning(message, {
    position: "top-center",
    className: "warning-message",
  });
};

export const successMessage = (message) => {
  toast.success(message, {
    position: "top-center",
    className: "success-message",
  });
};

export const dangerMessage = (message) => {
  toast.error(message, {
    position: "top-center",
    className: "danger-message",
  });
};

export const warningMessageCenter = (message) => {
  toast.success(message, { position: "top-center" });
};

export const successUpdate = (message) => {
  toast.success(message, { position: "top-center" });
};
