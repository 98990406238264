import React, { useState, useMemo, useEffect } from "react";
import useCustomMutation from "../../../../lib/Hooks/useCustomMutation";
import "./completeReview.css";
import { warningMessage } from "../../../Common/Toast/Toast";
import submitReview from "../../../../lib/utils/submitReview";

const Question = ({
  questionText,
  handleSelect,
  selectedValue,
  sectionKey,
  index,
  choices = [],
}) => {
  if (!Array.isArray(choices)) {
    console.error(
      `Expected choices to be an array but got ${typeof choices}`,
      choices
    );
    return null;
  }

  return (
    <div className="question">
      <p>{questionText}</p>
      <div className="choices gap-3">
        {choices.map((choice) => (
          <button
            key={choice}
            className={`choice-button ${
              selectedValue === choice ? "active" : ""
            }`}
            onClick={() => handleSelect(sectionKey, index, choice)}
          >
            {choice}
          </button>
        ))}
      </div>
    </div>
  );
};

const Section = ({
  sectionTitle,
  questions,
  sectionKey,
  handleSelect,
  answers,
  choicesPerQuestion,
}) => {
  return (
    <div className="section">
      <h3>
        {sectionTitle === "Quality of Work"
          ? "Quality of Work (Out of 15 Marks)"
          : sectionTitle === "Work Habits"
          ? "Work Habits (Out of 20 Marks)"
          : sectionTitle === "Job Knowledge"
          ? "Job Knowledge (Out of 15 Marks)"
          : sectionTitle === "Interpersonal Skills"
          ? "Interpersonal Skills (Out of 25 Marks)"
          : sectionTitle === "Leadership Skills"
          ? "Leadership Skills (Out of 25 Marks)"
          : null}
      </h3>
      {questions.map((question, index) => (
        <Question
          key={index}
          questionText={question}
          handleSelect={handleSelect}
          selectedValue={answers[sectionKey][index]}
          sectionKey={sectionKey}
          index={index}
          choices={choicesPerQuestion[index]}
        />
      ))}
    </div>
  );
};

const CompleteReview = ({
  setIsCompleteEvatuation,
  token,
  userID,
  organizationID,
  departmentID,
}) => {
  const [answers, setAnswers] = useState({
    qualityOfWork: Array(3).fill(null),
    workHabits: Array(4).fill(null),
    jobKnowledge: Array(3).fill(null),
    interpersonalSkills: Array(5).fill(null),
    leadershipSkills: Array(3).fill(null),
    overallProgress: {
      performanceFailing: null,
      performanceAcceptable: null,
      performanceOutstanding: null,
      comment: "",
      recommendation: "",
    },
  });

  const key = ["MyReviews", organizationID];
  const Mutation = useCustomMutation(key);

  const [currentStep, setCurrentStep] = useState(1);
  const [error, setError] = useState(null);

  // Memoize the total score calculation to optimize the performance
  const totalScore = useMemo(() => {
    let sum = 0;
    Object.keys(answers).forEach((section) => {
      if (Array.isArray(answers[section])) {
        sum += answers[section].reduce((acc, val) => acc + (val || 0), 0);
      }
    });
    return sum;
  }, [answers]);

  // console.log("totalScore...", totalScore);
  // console.log("answers...", answers);

  useEffect(() => {
    if (Mutation?.isSuccess) {
      setAnswers({
        qualityOfWork: Array(3).fill(null),
        workHabits: Array(4).fill(null),
        jobKnowledge: Array(3).fill(null),
        interpersonalSkills: Array(5).fill(null),
        leadershipSkills: Array(3).fill(null),
        overallProgress: {
          performanceFailing: null,
          performanceAcceptable: null,
          performanceOutstanding: null,
          comment: "",
          recommendation: "",
        },
      });
      setIsCompleteEvatuation(false);
    }
  }, [Mutation?.isSuccess]);

  const handleSelect = (sectionKey, questionIndex, value) => {
    setAnswers((prev) => {
      if (sectionKey === "overallProgress") {
        return {
          ...prev,
          [sectionKey]: {
            ...prev[sectionKey],
            [questionIndex]: value,
          },
        };
      } else {
        const updatedSection = [...prev[sectionKey]];
        updatedSection[questionIndex] = value;
        return { ...prev, [sectionKey]: updatedSection };
      }
    });
  };

  const handleInputChange = (sectionKey, inputField, value) => {
    setAnswers((prev) => ({
      ...prev,
      [sectionKey]: {
        ...prev[sectionKey],
        [inputField]: value,
      },
    }));
  };

  const canProceedToNextStep = () => {
    const mandatorySections = [
      "qualityOfWork",
      "workHabits",
      "jobKnowledge",
      "interpersonalSkills",
      "leadershipSkills",
      "overallProgress",
    ];

    for (let i = 0; i < mandatorySections.length; i++) {
      if (currentStep === i + 1) {
        const sectionKey = mandatorySections[i];
        if (Array.isArray(answers[sectionKey])) {
          if (answers[sectionKey].some((ans) => ans === null)) {
            setError(
              "Please fill all fields in this section before proceeding."
            );
            warningMessage(
              "Please fill all fields in this section before proceeding."
            );
            return false;
          }
        } else {
          if (
            answers[sectionKey].performanceFailing === null ||
            answers[sectionKey].performanceAcceptable === null ||
            answers[sectionKey].performanceOutstanding === null
          ) {
            setError("Please fill all overall progress answers.");
            warningMessage("Please fill all overall progress answers.");
            return false;
          }
        }
      }
    }

    setError(null);
    return true;
  };

  const nextStep = () => {
    if (canProceedToNextStep()) {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const prevStep = () => {
    setCurrentStep((prev) => prev - 1);
  };

  // manager: managerInitialData,
  const handleSubmit = (e) => {
    e.preventDefault();
    const outputData = submitReview(answers);
    const Method = "POST",
      url = `assessments?uid=${userID}&oid=${organizationID}&did=${departmentID}`;
    const raw = JSON.stringify({
      assessment: {
        employee: outputData,
      },
    });

    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Review successfully submitted",
      token: token,
    };
    Mutation.mutate(data);
  };

  return (
    <div>
      {/* {error && <div className="error-message">{error}</div>} */}

      <div className="evaluation-form">
        {/* Step 1: Quality of Work */}
        {currentStep === 1 && (
          <>
            <Section
              sectionTitle="Quality of Work"
              sectionKey="qualityOfWork"
              questions={[
                "Accuracy, neatness and timeliness of work",
                "Adherence to duties and procedures in Job Descriptions and Work Instructions",
                "Synchronization with organizational/functional goals",
              ]}
              choicesPerQuestion={[
                [1, 2, 3, 4, 5],
                [1, 2, 3, 4, 5],
                [1, 2, 3, 4, 5],
              ]}
              handleSelect={handleSelect}
              answers={answers}
            />
            <div className="form-navigation">
              <button className="next-button" onClick={nextStep}>
                Next
              </button>
            </div>
          </>
        )}

        {/* Step 2: Work Habits */}
        {currentStep === 2 && (
          <>
            <Section
              sectionTitle="Work Habits"
              sectionKey="workHabits"
              questions={[
                "Punctuality to workplace",
                "Attendance",
                "Does the employee stay busy, look for things to do, initiates at workplace",
                "Submits reports on time and meets deadlines",
              ]}
              choicesPerQuestion={[
                [1, 2, 3, 4, 5],
                [1, 2, 3, 4, 5],
                [1, 2, 3, 4, 5],
                [1, 2, 3, 4, 5],
              ]}
              handleSelect={handleSelect}
              answers={answers}
            />
            <div className="form-navigation">
              <button className="back-button" onClick={prevStep}>
                Back
              </button>
              <button className="next-button" onClick={nextStep}>
                Next
              </button>
            </div>
          </>
        )}

        {/* Step 3: Job Knowledge */}
        {currentStep === 3 && (
          <>
            <Section
              sectionTitle="Job Knowledge"
              sectionKey="jobKnowledge"
              questions={[
                "Skill and ability to perform job satisfactorily",
                "Shown interest in learning and improving",
                "Problem-solving ability",
              ]}
              choicesPerQuestion={[
                [1, 2, 3, 4, 5],
                [1, 2, 3, 4, 5],
                [1, 2, 3, 4, 5],
              ]}
              handleSelect={handleSelect}
              answers={answers}
            />
            <div className="form-navigation">
              <button className="back-button" onClick={prevStep}>
                Back
              </button>
              <button className="next-button" onClick={nextStep}>
                Next
              </button>
            </div>
          </>
        )}

        {/* Step 4: Interpersonal Skills */}
        {currentStep === 4 && (
          <>
            <Section
              sectionTitle="Interpersonal Skills"
              sectionKey="interpersonalSkills"
              questions={[
                "Responds and contributes to team efforts",
                "Responds positively to suggestions and instructions",
                "Keeps supervisor informed of all details",
                "Adapts well to changing circumstances",
                "Seeks feedback to improve",
              ]}
              choicesPerQuestion={[
                [1, 2, 3, 4, 5],
                [1, 2, 3, 4, 5],
                [1, 2, 3, 4, 5],
                [1, 2, 3, 4, 5],
                [1, 2, 3, 4, 5],
              ]}
              handleSelect={handleSelect}
              answers={answers}
            />
            <div className="form-navigation">
              <button className="back-button" onClick={prevStep}>
                Back
              </button>
              <button className="next-button" onClick={nextStep}>
                Next
              </button>
            </div>
          </>
        )}

        {/* Step 5: Leadership Skills */}
        {currentStep === 5 && (
          <>
            <Section
              sectionTitle="Leadership Skills"
              sectionKey="leadershipSkills"
              questions={[
                "Aspirant to climb up the ladder, accepts challenges, new responsibilities and roles. (out of 10)",
                "Innovative thinking - contribution to organizations and functions and personal growth. (out of 10)",
                "Work motivation.",
              ]}
              choicesPerQuestion={[
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                [1, 2, 3, 4, 5],
              ]}
              handleSelect={handleSelect}
              answers={answers}
            />
            <div className="form-navigation">
              <button className="back-button" onClick={prevStep}>
                Back
              </button>
              <button className="next-button" onClick={nextStep}>
                Next
              </button>
            </div>
          </>
        )}

        {/* Step 6: Overall Progress */}
        {currentStep === 6 && (
          <>
            <div className="section">
              <h3>Overall Progress</h3>

              <div className="question">
                <p>
                  Employee performance and learning is unsatisfactory and is
                  failing to improve at a satisfactory rate
                </p>
                <div className="choices gap-3">
                  <button
                    className={`choice-button ${
                      answers.overallProgress.performanceFailing === "Yes"
                        ? "active"
                        : ""
                    }`}
                    onClick={() =>
                      handleSelect(
                        "overallProgress",
                        "performanceFailing",
                        "Yes"
                      )
                    }
                  >
                    Yes
                  </button>
                  <button
                    className={`choice-button ${
                      answers.overallProgress.performanceFailing === "No"
                        ? "active"
                        : ""
                    }`}
                    onClick={() =>
                      handleSelect(
                        "overallProgress",
                        "performanceFailing",
                        "No"
                      )
                    }
                  >
                    No
                  </button>
                </div>
              </div>

              <div className="question">
                <p>
                  Employee performance and learning is acceptable and is
                  improving at a satisfactory rate
                </p>
                <div className="choices gap-3">
                  <button
                    className={`choice-button ${
                      answers.overallProgress.performanceAcceptable === "Yes"
                        ? "active"
                        : ""
                    }`}
                    onClick={() =>
                      handleSelect(
                        "overallProgress",
                        "performanceAcceptable",
                        "Yes"
                      )
                    }
                  >
                    Yes
                  </button>
                  <button
                    className={`choice-button ${
                      answers.overallProgress.performanceAcceptable === "No"
                        ? "active"
                        : ""
                    }`}
                    onClick={() =>
                      handleSelect(
                        "overallProgress",
                        "performanceAcceptable",
                        "No"
                      )
                    }
                  >
                    No
                  </button>
                </div>
              </div>

              <div className="question">
                <p>
                  Employee has successfully demonstrated outstanding overall
                  performance
                </p>
                <div className="choices gap-3">
                  <button
                    className={`choice-button ${
                      answers.overallProgress.performanceOutstanding === "Yes"
                        ? "active"
                        : ""
                    }`}
                    onClick={() =>
                      handleSelect(
                        "overallProgress",
                        "performanceOutstanding",
                        "Yes"
                      )
                    }
                  >
                    Yes
                  </button>
                  <button
                    className={`choice-button ${
                      answers.overallProgress.performanceOutstanding === "No"
                        ? "active"
                        : ""
                    }`}
                    onClick={() =>
                      handleSelect(
                        "overallProgress",
                        "performanceOutstanding",
                        "No"
                      )
                    }
                  >
                    No
                  </button>
                </div>
              </div>
            </div>

            <div className="form-navigation">
              <button className="back-button" onClick={prevStep}>
                Back
              </button>
              <button className="next-button" onClick={nextStep}>
                Next
              </button>
            </div>
          </>
        )}

        {/* Step 7: Comment & Final Comments */}
        {currentStep === 7 && (
          <>
            <div className="section">
              <h3>Comments</h3>

              <div className="question">
                <div>
                  <label>Write your comment below</label>
                </div>
                <textarea
                  value={answers.overallProgress.comment}
                  onChange={(e) =>
                    handleInputChange(
                      "overallProgress",
                      "comment",
                      e.target.value
                    )
                  }
                  className="w-100 p-2 rounded-3"
                  rows="4"
                />
              </div>

              {/* <div className="question">
                <div>
                  <label>Final Comments</label>
                </div>
                <textarea
                  value={answers.overallProgress.recommendation}
                  onChange={(e) =>
                    handleInputChange(
                      "overallProgress",
                      "recommendation",
                      e.target.value
                    )
                  }
                  className="w-100 p-2 rounded-3"
                  rows="4"
                />
              </div> */}
            </div>

            <div className="form-navigation">
              <button className="back-button" onClick={prevStep}>
                Back
              </button>
              {Mutation?.isLoading ? (
                <button className="submit-button">...Loading</button>
              ) : (
                <button className="submit-button" onClick={handleSubmit}>
                  Submit
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CompleteReview;
