import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Container, Wrapper, Title, EmplyeeContainerChart } from "./Style";
import MonthlyReviewChart from "../../../Common/MonthlyReviewChart";
import OverallPerformanceChart from "../../../Common/OverallPerformanceChart";

const myMonthlyAnalysisis = ({ employeeAnalytics }) => {
  const overallPercentage = employeeAnalytics?.data?.overallPerformanceScore;
  return (
    <React.Fragment>
      <Row>
        <Col md={6}>
          <Card style={Container}>
            <CardBody style={Wrapper}>
              <div style={Title}>Monthly Review</div>
              <div>
                <MonthlyReviewChart />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md={6}>
          <Card style={EmplyeeContainerChart}>
            <CardBody style={Wrapper}>
              <div style={Title}>Overall Performance</div>
              <div className="d-flex justify-content-center align-items-center pt-5">
                {overallPercentage && (
                  <OverallPerformanceChart
                    value={overallPercentage}
                    title="Good"
                  />
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default myMonthlyAnalysisis;
