import React from "react";
import { Card, CardBody } from "reactstrap";
import Profile from "../../../Common/Profile/Profile";
import { ContainerCard } from "./Style";

const ProfileCard = ({ employeeInfo }) => {
  return (
    <React.Fragment>
      <Card style={ContainerCard}>
        <CardBody
          className="w-100 p-4"
          // style={{ userSelect: "none" }}
        >
          <Profile
            name={employeeInfo?.data?.firstName}
            lastName={employeeInfo?.data?.lastName}
            email={employeeInfo?.data?.email}
            role={employeeInfo?.data?.roles[0]?.type}
            photo={employeeInfo?.data?.photo}
            userData={employeeInfo?.data}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default ProfileCard;
