import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Container, Wrapper, Title, SubTitle } from "./Style";
import MonthlyReviewChart from "../../../Common/MonthlyReviewChart";
import DepartmentalChart from "../../../Common/DepartmentalChart";

const MonthlyAnalysis = ({ orgAnalytics }) => {
  const deptOverall = orgAnalytics?.data?.departmentShares;
  return (
    <React.Fragment>
      <Row>
        <Col md={6}>
          <Card style={Container}>
            <CardBody style={Wrapper}>
              <div style={Title}>Monthly Review</div>
              <div>
                <MonthlyReviewChart />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md={6}>
          <Card style={Container}>
            <CardBody style={Wrapper}>
              <div style={Title}>Department Overall Performance</div>
              <div>
                <DepartmentalChart deptOverall={deptOverall} />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default MonthlyAnalysis;
