import React from "react";
import { Offcanvas, OffcanvasHeader, OffcanvasBody } from "reactstrap";
const OffCanvas = ({
  isOffcanvasOpen,
  title,
  body,
  toggleSaveChanges,
  directionOffCanvas,
  widthOffCanvas,
}) => {
  return (
    <Offcanvas
      isOpen={isOffcanvasOpen}
      toggle={toggleSaveChanges}
      direction={directionOffCanvas}
      style={{ width: widthOffCanvas }}
    >
      <OffcanvasHeader toggle={toggleSaveChanges}>{title}</OffcanvasHeader>
      <OffcanvasBody>{body}</OffcanvasBody>
    </Offcanvas>
  );
};
export default OffCanvas;
