import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useCustomFetchQuery from "../../lib/Hooks/useCustomFecthQuery";
import { Container } from "reactstrap";
import AdminReviewTable from "../../components/Pages/ReviewForm/Admin/AdminReviewTable";
import Loader from "../../components/Loader/LoaderEmployees";

const ReviewForm = () => {
  const Navigate = useNavigate();
  const obj = JSON.parse(localStorage.getItem("authHC"));
  const token = obj?.token;
  const userID = obj?.data?.data?.id;
  const organizationID = obj?.data?.data?.organizationId;

  const {
    data: getEmployees,
    error,
    isLoading,
  } = useCustomFetchQuery(
    ["AllEmployees", organizationID],
    `${process.env.REACT_APP_APIKEY}employees?oid=${organizationID}`,
    token,
    { enabled: !!organizationID }
  );

  if (isLoading) {
    return (
      <div className="page-content">
        <Loader />
      </div>
    );
  }

  // Filtering and sorting the data array
  const filteredEmployees =
    getEmployees?.data
      .filter((employee) =>
        employee?.roles?.some((role) =>
          ["Learner", "Intern", "Employee"].includes(role?.type)
        )
      )
      .sort((a, b) => a?.firstName?.localeCompare(b?.firstName)) || [];

  if (isLoading) {
    return (
      <div className="page-content">
        <Loader />
      </div>
    );
  }

  // console.log(
  //   "..Filtered Employees:...####,....",
  //   JSON.stringify(getEmployees, null, 2)
  // );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <AdminReviewTable
            Navigate={Navigate}
            token={token}
            organizationID={organizationID}
            employeeData={filteredEmployees}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ReviewForm;
