import React, { useState, useEffect, useRef } from "react";
import { Container, Alert } from "reactstrap";
import { composeInitialProps } from "react-i18next";
import "./employeeDetails.scss";

import { LiaUser } from "react-icons/lia";
import { IoImageOutline } from "react-icons/io5";
import { MdContentCopy } from "react-icons/md";

// import { Alert } from "@/components/ui/alert";

const EmployeeDetailsCopy = () => {
  const workerDetails = JSON.parse(localStorage.getItem("employeeDetailsHC"));

  const [inputs, setInputs] = useState({});
  const [profileImage, setProfileImage] = useState("");
  const [showCopyAlert, setShowCopyAlert] = useState(false);
  const fileInputRef = useRef(null);

  const onboardingTasks = [
    { name: "Office Tour", progress: 100 },
    { name: "Work Tools", progress: 20 },
    { name: "Meet Your Colleagues", progress: 0 },
  ];

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => setProfileImage(e.target.result);
      reader.readAsDataURL(file);
    }
  };

  const copyToClipboard = async (text) => {
    await navigator.clipboard.writeText(text);
    setShowCopyAlert(true);
    setTimeout(() => setShowCopyAlert(false), 2000);
  };

  useEffect(() => {
    if (workerDetails) {
      setInputs(workerDetails);
      // setProfileImage(workerDetails?.photo);
    }
  }, [workerDetails?._id]);

  // console.log("inputs...###...", JSON.stringify(inputs, null, 2));

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <div className="onb-container">
            <div className="onb-content-wrapper">
              {/* Left Column */}
              <div className="onb-left-col">
                <div className="onb-profile-section">
                  <div className="onb-image-wrapper">
                    {profileImage ? (
                      <img src={profileImage} alt="Profile" />
                    ) : (
                      <div className="w-100 h-100 d-flex justify-content-center align-items-center bg-primary rounded-3 ">
                        <LiaUser size={200} color="white" />
                      </div>
                    )}

                    <button
                      onClick={() => fileInputRef?.current?.click()}
                      className="onb-change-photo"
                    >
                      <IoImageOutline size={16} color="#33a6cd" />
                      Change Profile Image
                    </button>
                    <input
                      ref={fileInputRef}
                      type="file"
                      accept="image/*"
                      onChange={handleImageUpload}
                    />
                  </div>

                  <div className="onb-details">
                    {/* <h3>EMPLOYEE DETAILS</h3> */}

                    <div className="onb-field-group">
                      <label>Role</label>
                      <select>
                        {/* <option>{inputs?.roles[0]?.type}</option> */}
                        {inputs?.roles?.map((role, i) => {
                          return <option key={i}>{role?.type}</option>;
                        })}
                      </select>
                    </div>
                    <div className="onb-field-group">
                      <label>Department</label>
                      <select>
                        <option>Marketing</option>
                      </select>
                    </div>
                    <h3 className="mt-4">Residential address</h3>
                    <div className="onb-field-group">
                      <label>Physical address</label>
                      <input type="text" value={inputs.initials} readOnly />
                    </div>
                    <div className="onb-field-group">
                      <label>Unit number</label>
                      <input type="text" value={inputs.initials} readOnly />
                    </div>
                    <div className="onb-field-group">
                      <label>Complex</label>
                      <input type="text" value={inputs.initials} readOnly />
                    </div>
                    <div className="onb-field-group">
                      <label>Suburb / district</label>
                      <input type="text" value={inputs.initials} readOnly />
                    </div>
                    <div className="onb-field-group">
                      <label>City / Town</label>
                      <input type="text" value={inputs.initials} readOnly />
                    </div>
                    <div className="onb-field-group">
                      <label>Postal code</label>
                      <input type="text" value={inputs.initials} readOnly />
                    </div>
                    <div className="onb-field-group">
                      <label>Country</label>
                      <input type="text" value={inputs.initials} readOnly />
                    </div>

                    {/* <div className="onb-field-group">
                      <label>Position</label>
                      <input
                        type="text"
                        value={inputs.phoneNumber}
                        readOnly
                      />
                    </div> */}
                  </div>
                </div>

                <div className="onb-actions">
                  <button className="onb-save">Save Changes</button>
                  <button className="onb-cancel">Cancel</button>
                </div>
              </div>

              {/* Right Column */}
              <div className="onb-right-col">
                <div className="onb-role-section">
                  <div className="onb-team-section">
                    <h3>EMPLOYEE DETAILS</h3>
                    <div className="onb-field-group">
                      <label>Employee number</label>
                      <input
                        type="text"
                        value={inputs.employeeNumber}
                        readOnly
                      />
                    </div>
                    <div className="onb-field-group">
                      <label>Initials</label>
                      <input type="text" value={inputs.initials} readOnly />
                    </div>
                    <div className="onb-field-group">
                      <label>First Name</label>
                      <input type="text" value={inputs.firstName} readOnly />
                    </div>

                    <div className="onb-field-group">
                      <label>Last Name</label>
                      <input type="text" value={inputs.lastName} readOnly />
                    </div>

                    <div className="onb-field-group">
                      <label>Email Address</label>
                      <div className="onb-input-wrapper">
                        <input type="email" value={inputs.email} readOnly />
                        <span>
                          <MdContentCopy
                            size={17}
                            color="#33a6cd"
                            onClick={() => copyToClipboard(inputs.email)}
                          />
                        </span>
                      </div>
                    </div>

                    <div className="onb-field-group">
                      <label>Phone Number</label>
                      <div className="onb-input-wrapper">
                        <input type="tel" value={inputs.phoneNumber} readOnly />
                        <span>
                          <MdContentCopy
                            size={17}
                            color="#33a6cd"
                            onClick={() => copyToClipboard(inputs.phoneNumber)}
                          />
                        </span>
                      </div>
                    </div>

                    <div className="onb-field-group">
                      <label>Manager</label>
                      <select>
                        <option>Kirk Mitrohin</option>
                      </select>
                    </div>

                    <div className="onb-field-group">
                      <label>Lead</label>
                      <select>
                        <option>Eugene Hummell</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="onb-onboarding-section">
                  <div className="onb-header">
                    <h3>Employee Performance</h3>
                  </div>

                  <div className="onb-progress">
                    <div className="onb-progress-header">
                      <span>Current Status</span>
                      <span>35%</span>
                    </div>
                    <div className="onb-progress-bar">
                      <div style={{ width: "35%" }} />
                    </div>
                  </div>

                  <div className="onb-tasks">
                    {onboardingTasks.map((task, index) => (
                      <div key={index} className="onb-task">
                        <div className="onb-task-left">
                          <input
                            type="checkbox"
                            checked={task.progress === 100}
                            disabled={task.disabled}
                          />
                          <span className={task.disabled ? "disabled" : ""}>
                            {task.name}
                          </span>
                        </div>
                        <span>{task.progress}%</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {showCopyAlert && (
              <Alert className="onb-alert">Copied to clipboard!</Alert>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EmployeeDetailsCopy;
