import React from "react";
import { Container, Row, Col, Button } from "reactstrap";

const LoaderDashboard = () => {
  const lineStyle = {
    height: "16px",
    backgroundColor: "#e0e0e0",
    borderRadius: "4px",
    margin: "12px 0",
  };

  return (
    <div className="page-content">
      <Container fluid>
        {/* Top Bar with Search and Button */}
        <Row className="align-items-center mb-4">
          <Col md={6}>
            <div
              style={{
                height: "40px",
                width: "70%",
                backgroundColor: "#e0e0e0",
                borderRadius: "4px",
              }}
            ></div>
          </Col>
          <Col md={6} className="text-end">
            <Button
              style={{
                height: "40px",
                width: "120px",
                backgroundColor: "#e0e0e0",
                border: "none",
                borderRadius: "4px",
              }}
              disabled
            />
          </Col>
        </Row>

        {/* Table Skeleton */}
        <div className="p-3" style={{ backgroundColor: "#f9f9f9" }}>
          {/* Header Skeleton */}
          <Row>
            <Col md={4}>
              <div style={{ ...lineStyle, width: "100%" }}></div>
            </Col>
            <Col md={4}>
              <div style={{ ...lineStyle, width: "100%" }}></div>
            </Col>
            <Col md={4}>
              <div style={{ ...lineStyle, width: "100%" }}></div>
            </Col>
          </Row>
          {/* Data Rows Skeleton */}
          {Array.from({ length: 6 }).map((_, index) => (
            <Row key={index} className="mb-2">
              <Col md={4}>
                <div style={{ ...lineStyle, width: "100%" }}></div>
              </Col>
              <Col md={4}>
                <div style={{ ...lineStyle, width: "100%" }}></div>
              </Col>
              <Col md={4}>
                <div style={{ ...lineStyle, width: "100%" }}></div>
              </Col>
            </Row>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default LoaderDashboard;
