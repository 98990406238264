import React, { useState, useEffect } from "react";
import { Progress, Card, CardBody } from "reactstrap";
import DataTable from "react-data-table-component";
import { AiFillEye } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import { LuUser2 } from "react-icons/lu";
import SearchInput from "../../../Common/SearchIput";
import { employeeTblTitle, employeeTbl } from "../../../Common/TableStyle";

import OffCanvasFull from "../../../Common/OffCanvas/OffCanvasFull";

const AdminReviewTable = ({ Navigate, userID, token, employeeData }) => {
  const [search, setSearch] = useState("");
  const [employeeId, setEmployeeId] = useState(null);
  const [isAddProfile, setIsAddProfile] = useState(false);
  const [isEditProfile, setIsEditProfile] = useState(false);
  const toggleView = (id) => {
    Navigate(`/employee/${id}`);
  };
  const toggleEdit = (id) => {
    setEmployeeId(id);
  };
  const toggleAddOffCanvas = () => {
    setIsAddProfile(!isAddProfile);
  };
  const toggleEditOffCanvas = () => {
    setIsEditProfile(!isEditProfile);
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Employee Name</span>,
      cell: (row) => {
        return (
          <div style={{ width: "100%" }}>
            <div className="w-100 d-flex align-items-center gap-2">
              <div
                className="d-flex justify-content-center align-items-center bg-primary"
                style={{ width: 35, height: 35, borderRadius: "50%" }}
              >
                {row?.photo ? (
                  <img
                    src={row?.photo}
                    alt="employee-pic"
                    className="w-100 h-100"
                    style={{ borderRadius: "50%" }}
                  />
                ) : (
                  <LuUser2 color="white" size={19} />
                )}
              </div>
              <div className="">
                <div style={{ fontSize: 14, fontWeight: 400 }}>
                  {row?.firstName} {row?.lastName}
                </div>
                <div
                  style={{ fontSize: 12, fontWeight: 500, color: "#A7A7A7" }}
                >
                  {row?.email}
                </div>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Department</span>,
      selector: (row) => row?.departmentId?.name,
    },
    {
      name: <span className="font-weight-bold fs-13">Role</span>,
      selector: (row) => row?.roles[0]?.type,
    },

    {
      name: <span className="font-weight-bold fs-13">Action</span>,

      cell: (row) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <AiFillEye
                size={20}
                className="mx-1"
                onClick={() => toggleView(row?.id)}
              />
            </div>
          </div>
        );
      },
    },
  ];

  const filterEmployee = (employeeData, searchQuery) => {
    if (!searchQuery) return employeeData;
    return employeeData?.filter((item) =>
      [
        item?.firstName,
        item?.lastName,
        ,
        item?.department,
        item?.roles[0]?.type,
        item?.email,
      ].some((field) =>
        field?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  };
  const filteredEmployeeTable = filterEmployee(employeeData, search);

  const data = filteredEmployeeTable || [];

  return (
    <div className="mt-3 mb-5 w-100">
      <div className="w-100 mb-3 d-flex justify-content-start">
        <SearchInput
          Title={"Search"}
          search={search}
          setSearch={setSearch}
          radius={20}
        />
      </div>
      <Card style={employeeTbl}>
        <CardBody>
          <div style={employeeTblTitle}>Employee Review</div>
          <DataTable
            fixedHeader
            fixedHeaderScrollHeight="300px"
            columns={columns}
            responsive
            data={data}
            pagination
          />
        </CardBody>
      </Card>

      <OffCanvasFull
        isOffcanvasOpen={isAddProfile}
        title="Add Manager"
        body={<>{employeeId}</>}
        toggleSaveChanges={() => toggleAddOffCanvas()}
        directionOffCanvas="end"
        widthOffCanvas={"100%"}
        bgColor={"#fff"}
        bgClose="#33A6CD"
        closeText={"#fff"}
      />
    </div>
  );
};
export default AdminReviewTable;
