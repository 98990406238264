import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const ScoreComponent = ({ value, text, color }) => {
  return (
    <div style={{ width: 150, height: 150, margin: "10px" }}>
      <CircularProgressbar
        value={value}
        text={`${value}%`}
        styles={buildStyles({
          textColor: color,
          pathColor: color,
          trailColor: "#d6d6d6",
        })}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "5px",
          color: color,
          fontWeight: "bold",
        }}
      >
        {text}
      </div>
    </div>
  );
};

export default ScoreComponent;
