import React, { useState, useEffect } from "react";
import usePost from "../../../lib/Hooks/usePost";
// import useCustomMutation from "../../../../Hooks/useCustomMutation";
import EditProfilePicture from "../../Common/Profile/EditProfilePicture";
import ButtonSubmit from "../../Common/Buttons/ButtonSubmit";

const ProfileDetails = ({ userData, token }) => {
  const { execute, pending, data, status } = usePost();

  //
  // console.log("userData...#333...", JSON.stringify(userData, null, 2));

  const [showImage, setShowImage] = useState(null);
  const [uploadImg, setUploadImg] = useState(null);
  const [inputs, setInputs] = useState({});

  // console.log("uploadImg...", uploadImg);

  const SaveBtn = () => {
    const Method = "PATCH",
      url = `users/updateMe`,
      isJSON = true;
    var formdata = new FormData();
    if (uploadImg) {
      formdata.append("photo", uploadImg);
    }
    execute(
      url,
      formdata,
      Method,
      "Profile successfully updated",
      token,
      isJSON
    );
  };

  useEffect(() => {
    if (status === "success") {
      const user = data?.data;
      if (user) {
        localStorage.clear();
        localStorage.setItem("authHC", JSON.stringify(user));
      }
    }
    const { photo, firstName, lastName, position } = userData;
    setShowImage(photo);
    setInputs({
      firstName,
      lastName,
      position,
    });
  }, [status, userData, data?.data]);

  console.log("...new data...", data?.data);

  console.log("userData...", JSON.stringify(userData, null, 2));
  return (
    <div className="d-flex justify-content-between">
      <div className="w-100 d-flex">
        <div className="profile-img-container">
          <EditProfilePicture
            showImage={showImage}
            setShowImage={setShowImage}
            setUploadImg={setUploadImg}
            iconSize={80}
            iconColor="white"
            imgHeight={80}
            imgWidth={80}
            imgBorder={10}
            bgColor="#33A6CD"
          />
        </div>

        <div className="w-100 d-flex justify-content-between align-items-center mx-3 profile-container-main">
          <div>
            <div className="fw-bolder">
              {inputs?.firstName} {inputs?.lastName}
            </div>
            <div className="text-primary fw-bolder">TNG / employee</div>
            <div className="text-secondary fw-bold">{inputs?.position}</div>
          </div>
          <ButtonSubmit
            Title="Save Change"
            BackgroundColor="#33A6CD"
            ColorText="white"
            BorderColor="#33A6CD"
            borderRadius={20}
            handleOnclick={SaveBtn}
            pending={pending}
          />
        </div>
      </div>
    </div>
  );
};
export default ProfileDetails;
