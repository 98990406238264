import React from "react";
import { Box, Skeleton, Button } from "@mui/material";

const SkeletonCard = () => (
  <Box
    sx={{
      backgroundColor: "#fff",
      borderRadius: 2,
      mb: 3,
      p: 2,
      width: "100%",
    }}
  >
    {/* Picture in the middle */}
    <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
      <Skeleton
        variant="circular"
        width={100}
        height={100}
        sx={{ mt: 1, mb: 2 }}
      />
    </Box>

    {/* Two fields 50/50 */}
    <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
      <Skeleton variant="rectangular" width="48%" height={50} />
      <Skeleton variant="rectangular" width="48%" height={50} />
    </Box>

    {/* Update Button */}
    <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
      <Skeleton variant="rectangular" width="30%" height={40} />
    </Box>

    {/* Department Heading with Add feature */}
    <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
      <Skeleton variant="text" width="60%" height={30} />
      <Skeleton variant="circular" width={30} height={30} />
    </Box>

    {/* Roles */}
    <Box>
      <Skeleton variant="text" width="100%" height={30} sx={{ mb: 1 }} />
      <Skeleton variant="text" width="100%" height={30} sx={{ mb: 1 }} />
      <Skeleton variant="text" width="100%" height={30} />
    </Box>
  </Box>
);

const LoaderOrganizationSettings = () => {
  return (
    <Box sx={{ width: "100%" }}>
      {[...Array(3)].map((_, index) => (
        <SkeletonCard key={index} />
      ))}
    </Box>
  );
};

export default LoaderOrganizationSettings;
