export const employeeTblTitle = {
  width: "100%",
  display: "flex",
  padding: 5,
  justifyContent: "center",
  alignItems: "center",
  fontSize: 16,
  fontWeight: "bolder",
};

export const employeeTbl = {
  borderRadius: 10,
  padding: 5,
  borderWidth: 2,
  borderStyle: "solid",
  borderColor: "#D9D9D9",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
};

export const roleTblTitle = {
  width: "100%",
  display: "flex",
  padding: 5,
  justifyContent: "space-between",
  alignItems: "center",
  fontSize: 16,
  fontWeight: "bolder",
};
