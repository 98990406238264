import React from "react";

const SwitchButton = ({ isOn, handleToggle }) => {
  // Styles
  const switchContainerStyle = {
    display: "inline-block",
    cursor: "pointer",
    width: "50px",
    height: "25px",
    borderRadius: "25px",
    backgroundColor: isOn ? "#33A6CD" : "#ccc",
    position: "relative",
    transition: "background-color 0.3s",
  };

  const switchButtonStyle = {
    position: "absolute",
    top: "2px",
    left: isOn ? "27px" : "2px",
    width: "21px",
    height: "21px",
    borderRadius: "50%",
    backgroundColor: "#fff",
    transition: "left 0.3s",
  };

  return (
    <div onClick={handleToggle} style={switchContainerStyle}>
      <div style={switchButtonStyle}></div>
    </div>
  );
};

export default SwitchButton;
