import React, { useState } from "react";
import ViewUsePicture from "./ViewUsePicture";
import OffCanvas from "../OffCanvas/OffCanvas";
import { FaEye } from "react-icons/fa";
// FaEdit;
import EmployeeDetails from "../../../Pages/People/Employees/EmployeeDetails";

const Profile = ({ name, lastName, email, role, photo, userData }) => {
  const [isViewProfile, setIsViewProfile] = useState(false);
  // const [isEditProfile, setIsEditProfile] = useState(false);
  const [hoveredButton, setHoveredButton] = useState(null);
  const [employeeDetails, setEmployeeDetails] = useState(null);

  const toggleView = () => {
    setEmployeeDetails(userData);
    setIsViewProfile(!isViewProfile);
  };

  // const toggleEdit = () => {
  //   setIsEditProfile(!isEditProfile);
  // };

  const handleMouseEnter = (button) => {
    setHoveredButton(button);
  };

  const handleMouseLeave = () => {
    setHoveredButton(null);
  };

  const profileBtnStyle = (button) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: hoveredButton === button ? "#666666" : "#33A6CD",
    width: 40,
    height: 40,
    borderRadius: 40,
    transition: "background-color 0.3s ease",
  });

  return (
    <div className="d-flex justify-content-between">
      <div className="w-75 d-flex">
        <div className="profile-img-container">
          <ViewUsePicture
            showImage={photo}
            iconSize={80}
            iconColor="white"
            imgHeight={80}
            imgWidth={80}
            imgBorder={10}
            bgColor="#33A6CD"
          />
        </div>

        <div className="d-flex justify-content-between align-items-center mx-3 profile-container-main">
          <div>
            <div
              className="profile-container-Title"
              style={{ fontSize: 16, fontWeight: "bolder" }}
            >
              {name} {lastName}
            </div>
            <div
              className="profile-container-subtitle text-primary"
              style={{ fontSize: 14 }}
            >
              {email}
            </div>
            <div
              className="profile-container-subtitle "
              style={{ fontSize: 14, color: "#7C7C7C" }}
            >
              {role}
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <div
          style={profileBtnStyle("view")}
          onClick={toggleView}
          onMouseEnter={() => handleMouseEnter("view")}
          onMouseLeave={handleMouseLeave}
        >
          <FaEye size={16} color="white" />
        </div>
        {/* <div className="d-flex justify-content align-items-center mx-2">
          <div
            style={profileBtnStyle("edit")}
            onClick={toggleEdit}
            onMouseEnter={() => handleMouseEnter("edit")}
            onMouseLeave={handleMouseLeave}
          >
            <FaEdit size={16} color="white" />
          </div>
        </div> */}
      </div>

      <OffCanvas
        isOffcanvasOpen={isViewProfile}
        title="View Employee Details"
        body={<EmployeeDetails employeeDetails={employeeDetails} />}
        toggleSaveChanges={() => toggleView()}
        directionOffCanvas="end"
        widthOffCanvas={"100%"}
      />

      {/* <OffCanvas
        isOffcanvasOpen={isEditProfile}
        title="Edit Profile Details"
        body={<>Hello</>}
        toggleSaveChanges={() => toggleEdit()}
        directionOffCanvas="end"
        widthOffCanvas={400}
      /> */}
    </div>
  );
};

export default Profile;
