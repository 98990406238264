export const convertReviewsDate = (dateString = new Date()) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString("en-US", { month: "short" });
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
};

const obj = JSON.parse(localStorage.getItem("authHC"));
const user_id = obj?.data?.data?._id;
const firstName = obj?.data?.data?.firstName;
const lastName = obj?.data?.data?.lastName;

export const employeeInitialData = [
  {
    _id: "672a27836406f7c1f1b7a60bcc",
    object: "assessments",
    assessment: {
      employee: {
        data: [],
        overall: [],
        recommendation: "",
        comment: "I tried very hard",
        total: 71,
        _id: "672a27836406f7c1f1b7a60cc",
        id: "672a27836406f7c1f1b7a60cc",
      },
      _id: "672a27836406f7c1f1b7a60ccc",
    },
    date: convertReviewsDate("2024-11-05T14:09:53.244Z"),
    departmentId: "6724d33de4f5ba59b9639a8e",
    organizationId: "6724d2a2e4f5ba59b9639a58",
    userId: {
      _id: user_id,
      firstName: firstName,
      lastName: lastName,
      status: true,
      id: user_id,
    },
    status: "Pending",
    createdAt: convertReviewsDate(),
    __v: 0,
    id: "672a27836406f7c1f1b7a60bcc",
  },
];
