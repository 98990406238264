import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const PersonalForm = ({ inputs, setInputs, setNext }) => {
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  useEffect(() => {
    if (
      inputs.firstName &&
      inputs.lastName &&
      inputs.email &&
      inputs.phoneNumber
    ) {
      setNext(true);
    } else {
      setNext(false);
    }
  }, [inputs, setNext]);

  return (
    <>
      <Row>
        <Col md={6}>
          <label>
            First name <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            name="firstName"
            value={inputs.firstName}
            placeholder="Enter first name"
            onChange={handleChange}
            style={{ borderRadius: 7 }}
            className="mb-3 form-control fromInput"
            required
          />
        </Col>
        <Col md={6}>
          <label>
            Last name <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            name="lastName"
            value={inputs.lastName}
            placeholder="Enter last name"
            onChange={handleChange}
            style={{ borderRadius: 7 }}
            className="mb-3 form-control fromInput"
            required
          />
        </Col>
        <Col md={6}>
          <label>
            Email <span className="text-danger">*</span>
          </label>
          <input
            type="email"
            name="email"
            value={inputs.email}
            placeholder="Enter email"
            onChange={handleChange}
            style={{ borderRadius: 7 }}
            className="mb-3 form-control fromInput"
            required
          />
        </Col>
        <Col md={6}>
          <label>
            Phone Number <span className="text-danger">*</span>
          </label>
          <div className="phone-input-container">
            <PhoneInput
              international
              countryCallingCodeEditable={false}
              defaultCountry="ZA"
              value={inputs.phoneNumber ? inputs.phoneNumber : null}
              onChange={(value) => setInputs({ ...inputs, phoneNumber: value })}
              className="custom-phone-input"
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default PersonalForm;
