import React from "react";
import { Row, Col } from "reactstrap";
import Summary from "./Summary";
import GuideLine from "./GuideLine";
// import { BiSolidFilePdf } from "react-icons/bi";
import { IoMdPrint } from "react-icons/io";
import "./completeReview.css";

// Function to extract data for each section
const getSectionData = (sectionName, reviewDetails) => {
  const sectionData = reviewDetails?.assessment?.manager?.data;

  // Look within "Functional Skills" and other main categories for the specified section
  const functionalSkills = sectionData?.find(
    (item) => item.name === "Functional Skills"
  );

  const criteria =
    functionalSkills?.criteria?.find(
      (criterion) => criterion?.name === sectionName
    ) ||
    sectionData?.find((section) => section?.name === sectionName)?.criteria[0];

  return (
    criteria?.evaluations?.map((evaluation) => ({
      attribute: evaluation?.attribute,
      score: evaluation?.score,
    })) || []
  );
};

// Question Component
const Question = ({ questionText, selectedValue, choices = [] }) => {
  return (
    <div className="question">
      <p>{questionText}</p>
      <div className="choices gap-3">
        {choices?.map((choice) => (
          <button
            key={choice}
            className={`choice-button ${
              selectedValue === choice ? "active" : ""
            }`}
            disabled
          >
            {choice}
          </button>
        ))}
      </div>
    </div>
  );
};

// Section Component
const Section = ({ sectionTitle, questions, choicesPerQuestion }) => {
  return (
    <div className="section">
      <h3>{sectionTitle}</h3>
      {questions?.map((question, index) => (
        <Question
          key={index}
          questionText={question.attribute}
          selectedValue={question?.score}
          choices={choicesPerQuestion[index]}
        />
      ))}
    </div>
  );
};

const ViewReview = ({ reviewDetails }) => {
  const overallProgress = reviewDetails?.assessment?.manager?.overall || [];
  const handlePrint = () => {
    window.print();
  };

  const handleDownloadPDF = () => {
    // const doc = new jsPDF();
    // doc.text("Review Details", 10, 10);
    // doc.text(JSON.stringify(reviewDetails, null, 2), 10, 20); // Adjust content as needed
    // doc.save("review-details.pdf");
  };

  // console.log("....review...", JSON.stringify(reviewDetails?.status, null, 2));

  return (
    <div className="evaluation-view-form-container">
      <Row>
        <Col md={12}>
          {reviewDetails?.status === "Submitted" ? (
            <div className="w-100 mb-3 d-flex justify-content-start gap-3">
              <IoMdPrint size={40} color="#33a6cd" onClick={handlePrint} />
              {/* <BiSolidFilePdf size={40} color="#33a6cd" onClick={() => {}} /> */}
            </div>
          ) : null}
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={8}>
          <div className="evaluation-review-form">
            {/* Quality of Work Section */}
            <Section
              sectionTitle="Quality of Work (Out of 15 Marks)"
              questions={getSectionData("Quality of Work", reviewDetails)}
              choicesPerQuestion={[
                [1, 2, 3, 4, 5],
                [1, 2, 3, 4, 5],
                [1, 2, 3, 4, 5],
              ]}
            />

            {/* Work Habits Section */}
            <Section
              sectionTitle="Work Habits (Out of 20 Marks)"
              questions={getSectionData("Work Habits", reviewDetails)}
              choicesPerQuestion={[
                [1, 2, 3, 4, 5],
                [1, 2, 3, 4, 5],
                [1, 2, 3, 4, 5],
                [1, 2, 3, 4, 5],
              ]}
            />

            {/* Job Knowledge Section */}
            <Section
              sectionTitle="Job Knowledge (Out of 15 Marks)"
              questions={getSectionData("Job Knowledge", reviewDetails)}
              choicesPerQuestion={[
                [1, 2, 3, 4, 5],
                [1, 2, 3, 4, 5],
                [1, 2, 3, 4, 5],
              ]}
            />

            {/* Interpersonal Skills Section */}
            <Section
              sectionTitle="Interpersonal Skills (Out of 25 Marks)"
              questions={getSectionData("Interpersonal Skills", reviewDetails)}
              choicesPerQuestion={[
                [1, 2, 3, 4, 5],
                [1, 2, 3, 4, 5],
                [1, 2, 3, 4, 5],
                [1, 2, 3, 4, 5],
                [1, 2, 3, 4, 5],
              ]}
            />

            {/* Leadership Skills Section */}
            <Section
              sectionTitle="Leadership Skills (Out of 25 Marks)"
              questions={getSectionData("Leadership Skills", reviewDetails)}
              choicesPerQuestion={[
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                [1, 2, 3, 4, 5],
              ]}
            />

            {/* Overall Progress Section */}
            <div className="section">
              <h3>Overall Progress</h3>
              {overallProgress.map((progress, index) => (
                <div key={index} className="question">
                  <p>{progress.attribute}</p>
                  <div className="choices gap-3">
                    <button
                      className={`choice-button ${
                        progress.value === "Yes" ? "active" : ""
                      }`}
                      disabled
                    >
                      Yes
                    </button>
                    <button
                      className={`choice-button ${
                        progress.value === "No" ? "active" : ""
                      }`}
                      disabled
                    >
                      No
                    </button>
                  </div>
                </div>
              ))}
            </div>

            {/* Recommendations & Final Comments Section */}
            <div className="section">
              <h3>Comment & Recommendation</h3>
              <div className="question">
                <label>Employee Comment</label>
                <textarea
                  readOnly
                  value={reviewDetails?.assessment?.employee?.comment || ""}
                  className="w-100 p-2 rounded-3"
                  rows="4"
                />
              </div>
              <div className="question">
                <label>Manager Recommendation</label>
                <textarea
                  readOnly
                  value={
                    reviewDetails?.assessment?.manager?.recommendation || ""
                  }
                  className="w-100 p-2 rounded-3"
                  rows="4"
                />
              </div>

              <div className="w-100 d-flex justify-content-between">
                <div className="w-100">
                  <label>Manager Signature</label>
                  <div>
                    {reviewDetails?.managerSignature ? (
                      <img
                        src={reviewDetails?.managerSignature}
                        alt="manager-signature"
                        style={{ width: 100, height: 50, objectFit: "contain" }}
                      />
                    ) : (
                      <div className="text-danger">
                        Manager signature is required
                      </div>
                    )}
                  </div>
                </div>
                <div className="w-100 d-flex justify-content-end">
                  <div>
                    <div className="w-100 d-flex justify-content-end">
                      <label>Employee Signature</label>
                    </div>
                    <div>
                      {reviewDetails?.employeeSignature ? (
                        <img
                          src={reviewDetails?.employeeSignature}
                          alt="employee-signature"
                          style={{
                            width: 100,
                            height: 50,
                            objectFit: "contain",
                          }}
                        />
                      ) : (
                        <div className="text-danger">
                          Employee signature is required
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col md={12} lg={4}>
          <Summary result={reviewDetails?.assessment?.manager} />
          <GuideLine />
        </Col>
      </Row>
    </div>
  );
};

export default ViewReview;
