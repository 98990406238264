import React, { useRef, useState } from "react";
import { FaPlus, FaEdit } from "react-icons/fa";

const Logo = ({ imageURL, setImageURL }) => {
  const [showAddIcon, setShowAddIcon] = useState(true);
  const [hovering, setHovering] = useState(false);
  const fileInputRef = useRef(null);
  const handleDivClick = () => {
    fileInputRef.current.click();
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageURL(e.target.result);
        setShowAddIcon(false);
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <div className="mx-2">
      <div
        style={{
          width: 200,
          height: 120,
          position: "relative",
          userSelect: "none",
        }}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        <img
          src={imageURL}
          className="w-100 h-100 rounded-3"
          alt="picture"
          style={{
            position: "absolute",
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: "#ececec",
          }}
        />
        {showAddIcon && (
          <div
            onClick={handleDivClick}
            style={{
              position: "absolute",
              top: "35%",
              left: "40%",
              backgroundColor: "white",
              width: 40,
              height: 40,
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
              cursor: "pointer",
            }}
          >
            <FaPlus size={20} color="black" />
          </div>
        )}
        {hovering && !showAddIcon && (
          <div
            onClick={handleDivClick}
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 10,
              cursor: "pointer",
            }}
          >
            <div
              className=""
              style={{
                width: 40,
                height: 40,
                borderRadius: "50%",
                backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FaEdit size={20} color="black" />
            </div>
          </div>
        )}
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          style={{ display: "none" }}
          accept="image/*"
        />
      </div>
    </div>
  );
};

export default Logo;
