import React from "react";
import { useParams } from "react-router-dom";
import useCustomFetchQuery from "../../../lib/Hooks/useCustomFecthQuery";
import { Container } from "reactstrap";
import ProfileCard from "../../../components/Pages/People/Employee/ProfileCard";
import EmployeePerformanceTable from "../../../components/Pages/People/Employee/EmployeePerformanceTable";
// import { composeInitialProps } from "react-i18next";

const EmployeePerformance = () => {
  const params = useParams();
  const employeeID = params.ID;
  const obj = JSON.parse(localStorage.getItem("authHC"));
  const token = obj?.token;
  // const organizationID = obj?.data?.data?.organizationId;

  const {
    data: employeeInfo,
    error: errorEmployeeInfo,
    isLoading: isLoadingEmployeeInfo,
  } = useCustomFetchQuery(
    ["employeePersonalInfo", employeeID],
    `${process.env.REACT_APP_APIKEY}users/${employeeID}`,
    token,
    { enabled: !!employeeID }
  );

  const {
    data: employeeReviews,
    error,
    isLoading,
  } = useCustomFetchQuery(
    ["employeeReviews", employeeID],
    `${process.env.REACT_APP_APIKEY}assessments/user/${employeeID}`,
    token,
    { enabled: !!employeeID }
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <ProfileCard employeeInfo={employeeInfo} />
          <EmployeePerformanceTable employeeReviews={employeeReviews} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EmployeePerformance;
