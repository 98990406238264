import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "../withRouter";

// users
import userPicture from "../../../assets/images/users/user-icon.png";

const ProfileMenu = (props) => {
  const obj = JSON.parse(localStorage.getItem("authHC"));
  const userData = obj?.data?.data;
  const firstName = userData?.firstName;
  const lastName = userData?.lastName;
  const role = obj?.data?.data?.roles[0];
  const photo = obj?.data?.data?.photo;

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          {photo ? (
            <img
              className="rounded-circle header-profile-user"
              src={photo}
              alt="Header Avatar"
            />
          ) : (
            <img
              className="rounded-circle header-profile-user"
              src={userPicture}
              alt="Header Avatar"
            />
          )}

          <span className="d-none d-xl-inline-block ms-2 me-2">
            {firstName} {`${lastName}`.substring(0, 1)}.
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="/userprofile">
            <i className="ri-user-line align-middle me-2" />
            {props.t("Profile")}
          </DropdownItem>
          {/* <DropdownItem tag="a" href="#">
            <i className="ri-wallet-2-line align-middle me-2" />
            {props.t("My Wallet")}
          </DropdownItem> */}
          {role?.type === "Admin" ? (
            <DropdownItem tag="a" href="/organization-settings">
              <i className="ri-settings-2-line align-middle me-2" />
              {props.t("Settings")}
            </DropdownItem>
          ) : null}
          {role?.type === "Admin" ? (
            <DropdownItem tag="a" href="/subscriptions">
              <i className="mdi mdi-credit-card-multiple align-middle me-2" />
              {props.t("Subscriptions")}
            </DropdownItem>
          ) : null}

          {/* <DropdownItem tag="a" href="auth-lock-screen">
            <i className="ri-lock-unlock-line align-middle me-2" />
            {props.t("Lock screen")}
          </DropdownItem> */}
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="ri-shut-down-line align-middle me-2 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
          {/* <Link to="/logout" className="dropdown-item">
            <i className="ri-shut-down-line align-middle me-2 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link> */}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { error, success } = state.profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
