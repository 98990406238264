import React from "react";
import MessageCard from "../../components/Pages/Authentication/MessageCard";

function Message() {
  return (
    <div className="message-page">
      <MessageCard />
    </div>
  );
}

export default Message;
