import React from "react";
import { useNavigate } from "react-router-dom";
import { FaCheck } from "react-icons/fa";

const MessageCard = () => {
  const Navigate = useNavigate();
  return (
    <div className="message-card-content">
      <div className="message-card-icon">
        <FaCheck size={27} color="white" />
      </div>
      <h2>Success !</h2>
      <p>
        You have successfully registered <br /> Please Check your emial
      </p>

      <button
        className="message-card-button"
        onClick={() => {
          Navigate(`/pricing`);
        }}
      >
        OK
      </button>
    </div>
  );
};

export default MessageCard;
