import PropTypes from "prop-types";
import React from "react";
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap";

// Redux imports
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { Link } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";

// Formik validation and Yup schema
import * as Yup from "yup";
import { useFormik } from "formik";

// Action import
import { userForgetPassword } from "../../store/actions";

// Import logo
import logoKago from "../../../src/assets/images/kago-logo.png";

// OTP component definition
const Otp = (props) => {
  const dispatch = useDispatch();
  document.title = "OTP | Human Capital";

  // Formik setup for form validation and submission
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      otp: "", // New field for OTP
    },
    validationSchema: Yup.object({
      otp: Yup.string().required("Please enter OTP"), // Validate OTP field
    }),
    onSubmit: (values) => {
      dispatch(userForgetPassword(values, props.router.navigate));
    },
  });

  // Selector to access the forgetPassword state
  const forgetpass = createSelector(
    (state) => state.forgetPassword,
    (layout) => ({
      forgetSuccessMsg: layout.forgetSuccessMsg,
    })
  );

  // Destructure state values
  const { forgetSuccessMsg, forgetError } = useSelector(forgetpass);

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5" style={{ backgroundColor: "#f5f5f5" }}>
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                {/* Banner Section */}
                <div className="bg-primary" style={{ backgroundColor: "#33a6cd", padding: "40px 20px" }}>
                  <Row>
                    <Col xs={12}>
                      <div className="text-white p-4">
                        <h5 className="text-white">Enter OTP</h5>
                        <p>Please enter the OTP sent to your email.</p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="d-flex justify-content-center">
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoKago}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {/* Alert Messages */}
                    {forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null}

                    {/* Form Section */}
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">OTP</Label>
                        <Input
                          name="otp"
                          className="form-control"
                          placeholder="Enter OTP"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.otp || ""}
                          invalid={
                            validation.touched.otp && validation.errors.otp
                              ? true
                              : false
                          }
                        />
                        {validation.touched.otp && validation.errors.otp ? (
                          <FormFeedback type="invalid">
                            {validation.errors.otp}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <Row className="mb-3">
                        <Col className="text-end">
                          <button
                            className="btn btn-primary w-md"
                            type="submit"
                            style={{
                              backgroundColor: "#33a6cd",
                              borderColor: "#33a6cd",
                            }}
                          >
                            Verify OTP
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Didn't receive an OTP?{" "}
                  <Link to="#" className="font-weight-medium text-primary">
                    Resend OTP
                  </Link>
                </p>
                <p>
                  Go back to{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    Login
                  </Link>
                </p>
                <div className="account-pages-brandMark">
                  <a href="https://tngsolutions.co.za/">
                    By TNG Solutions | Developed in South Africa
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

// PropTypes for type-checking
Otp.propTypes = {
  history: PropTypes.object,
};

// Export the component with router props
export default withRouter(Otp);
