import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { UserSubscriptionProvider } from "./helpers/UserSubscriptionContext";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import "./i18n";

// import toastify for notification
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { configureStore } from "./store/store";
const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={configureStore({})}>
    <UserSubscriptionProvider>
      <QueryClientProvider client={queryClient}>
        <React.Fragment>
          <BrowserRouter>
            <App />
            <ToastContainer />
          </BrowserRouter>
        </React.Fragment>
      </QueryClientProvider>
    </UserSubscriptionProvider>
  </Provider>
);
reportWebVitals();
// serviceWorker.unregister();
