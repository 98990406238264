import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import {
  Container,
  Wrapper,
  Title,
  SubTitle,
  SubTitleBest,
  SubTitleLeast,
} from "./Style";

const CardItem = ({ orgAnalytics }) => {
  return (
    <React.Fragment>
      <Row>
        <Col md={3}>
          <Card style={Container}>
            <CardBody style={Wrapper}>
              <div style={Title}>Employee(s)</div>
              <div style={SubTitle}>{orgAnalytics?.data?.employeeCount}</div>
            </CardBody>
          </Card>
        </Col>
        <Col md={3}>
          <Card style={Container}>
            <CardBody style={Wrapper}>
              <div style={Title}>Department(s)</div>
              <div style={SubTitle}>{orgAnalytics?.data?.departmentCount}</div>
            </CardBody>
          </Card>
        </Col>
        <Col md={3}>
          <Card style={Container}>
            <CardBody style={Wrapper}>
              <div style={Title}>Most Performing Department</div>
              <div style={SubTitleBest}>
                {orgAnalytics?.data?.mostPerformingDept}
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md={3}>
          <Card style={Container}>
            <CardBody style={Wrapper}>
              <div style={Title}>Least Performing Department</div>
              <div style={SubTitleLeast}>
                {orgAnalytics?.data?.leastPerformingDept}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default CardItem;
