import React from "react";
import { Box, Skeleton } from "@mui/material";

const LoaderMySubscription = () => (
  <Box
    sx={{
      maxWidth: "800px",
      padding: "24px",
      backgroundColor: "#fff",
      borderRadius: 2,
    }}
  >
    {/* Title */}
    <Skeleton variant="text" width="30%" height={40} sx={{ mb: 3 }} />

    {/* Plans Container */}
    <Box sx={{ display: "flex", gap: "16px", mb: 3 }}>
      {/* Current Plan Card Skeleton */}
      <Box
        sx={{
          flex: "1",
          padding: "16px",
          borderRadius: "8px",
          border: "1px solid #33a6cd",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          minHeight: "160px",
        }}
      >
        {/* Plan Icon */}
        <Skeleton
          variant="circular"
          width={25}
          height={25}
          sx={{ position: "absolute", top: "-8px", right: "-8px" }}
        />

        {/* Plan Header */}
        <Box sx={{ mb: 2 }}>
          <Skeleton variant="text" width="50%" height={30} />
          <Skeleton variant="text" width="30%" height={25} />
        </Box>

        {/* Activation Notice */}
        <Skeleton variant="text" width="80%" height={20} sx={{ mb: 2 }} />

        {/* Button */}
        <Skeleton variant="rectangular" width="100%" height={35} />
      </Box>

      {/* Contact Us Card Skeleton */}
      <Box
        sx={{
          flex: "1",
          padding: "16px",
          borderRadius: "8px",
          background: `linear-gradient(to right, #33a6cd, #6FBFE0)`,
          color: "white",
        }}
      >
        <Skeleton variant="text" width="60%" height={30} sx={{ mb: 2 }} />
        <Skeleton variant="text" width="100%" height={20} sx={{ mb: 2 }} />
        <Skeleton variant="rectangular" width="80%" height={35} />
      </Box>
    </Box>

    {/* Toggle Description */}
    <Skeleton variant="text" width="100%" height={20} />
  </Box>
);

export default LoaderMySubscription;
