import React, { useState, useEffect } from "react";
import { Container, Alert } from "reactstrap";
import "./employeeDetails.scss";

import { LiaUser } from "react-icons/lia";
import { MdContentCopy } from "react-icons/md";

// import { Alert } from "@/components/ui/alert";

const EmployeeDetails = ({ employeeDetails }) => {
  const [inputs, setInputs] = useState({});
  const [showCopyAlert, setShowCopyAlert] = useState(false);

  const copyToClipboard = async (text) => {
    await navigator.clipboard.writeText(text);
    setShowCopyAlert(true);
    setTimeout(() => setShowCopyAlert(false), 2000);
  };

  useEffect(() => {
    if (employeeDetails) {
      setInputs(employeeDetails);
    }
  }, [employeeDetails?._id]);

  return (
    <React.Fragment>
      <div className="">
        <Container fluid={true}>
          <div className="onb-container">
            <div className="onb-content-wrapper">
              {/* Left Column */}
              <div className="onb-left-col">
                <div className="onb-profile-section">
                  <div className="onb-image-wrapper">
                    {inputs?.photo ? (
                      <img src={inputs?.photo} alt="Profile" />
                    ) : (
                      <div className="w-100 h-100 d-flex justify-content-center align-items-center bg-primary rounded-3 ">
                        <LiaUser size={200} color="white" />
                      </div>
                    )}
                  </div>

                  <div className="onb-details">
                    {/* <h3>EMPLOYEE DETAILS</h3> */}

                    <div className="onb-field-group">
                      <label>Role</label>
                      <select>
                        {/* <option>{inputs?.roles[0]?.type}</option> */}
                        {inputs?.roles?.map((role, i) => {
                          return <option key={i}>{role?.type}</option>;
                        })}
                      </select>
                    </div>
                    <div className="onb-field-group">
                      <label>Department</label>
                      <select>
                        <option>{inputs?.departmentId?.name}</option>
                      </select>
                    </div>
                    <h3 className="mt-4">Residential address</h3>
                    <div className="onb-field-group">
                      <label>Physical address</label>
                      <input type="text" value={inputs?.initials} readOnly />
                    </div>
                    <div className="onb-field-group">
                      <label>Unit number</label>
                      <input type="text" value={inputs.initials} readOnly />
                    </div>
                    <div className="onb-field-group">
                      <label>Complex</label>
                      <input type="text" value={inputs.initials} readOnly />
                    </div>
                    <div className="onb-field-group">
                      <label>Suburb / district</label>
                      <input type="text" value={inputs.initials} readOnly />
                    </div>
                    <div className="onb-field-group">
                      <label>City / Town</label>
                      <input type="text" value={inputs.initials} readOnly />
                    </div>
                    <div className="onb-field-group">
                      <label>Postal code</label>
                      <input type="text" value={inputs.initials} readOnly />
                    </div>
                    <div className="onb-field-group">
                      <label>Country</label>
                      <input type="text" value={inputs.initials} readOnly />
                    </div>

                    {/* <div className="onb-field-group">
                      <label>Position</label>
                      <input
                        type="text"
                        value={inputs.phoneNumber}
                        readOnly
                      />
                    </div> */}
                  </div>
                </div>

                <div className="onb-actions">
                  {/* <button className="onb-delete">Delete Employee</button> */}
                  {/* <button className="onb-cancel">Cancel</button> */}
                </div>
              </div>

              {/* Right Column */}
              <div className="onb-right-col">
                <div className="onb-role-section">
                  <div className="onb-team-section">
                    <h3>EMPLOYEE DETAILS</h3>
                    <div className="onb-field-group">
                      <label>Employee number</label>
                      <input
                        type="text"
                        // value={inputs.employeeNumber}
                        value="0589"
                        readOnly
                      />
                    </div>
                    <div className="onb-field-group">
                      <label>Initials</label>
                      <input type="text" value={inputs.initials} readOnly />
                    </div>
                    <div className="onb-field-group">
                      <label>First Name</label>
                      <input type="text" value={inputs.firstName} readOnly />
                    </div>

                    <div className="onb-field-group">
                      <label>Last Name</label>
                      <input type="text" value={inputs.lastName} readOnly />
                    </div>

                    <div className="onb-field-group">
                      <label>Email Address</label>
                      <div className="onb-input-wrapper">
                        <input type="email" value={inputs.email} readOnly />
                        <span>
                          <MdContentCopy
                            size={17}
                            color="#33a6cd"
                            onClick={() => copyToClipboard(inputs.email)}
                          />
                        </span>
                      </div>
                    </div>

                    <div className="onb-field-group">
                      <label>Phone Number</label>
                      <div className="onb-input-wrapper">
                        <input type="tel" value={inputs.phoneNumber} readOnly />
                        <span>
                          <MdContentCopy
                            size={17}
                            color="#33a6cd"
                            onClick={() => copyToClipboard(inputs.phoneNumber)}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="onb-onboarding-section">
                  <div className="onb-header">
                    <h3>Employee Performance</h3>
                  </div>

                  <div className="onb-progress">
                    <div className="onb-progress-header">
                      <span>Score</span>
                      <span>67%</span>
                    </div>
                    <div className="onb-progress-bar">
                      <div style={{ width: "35%" }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {showCopyAlert && (
              <Alert className="onb-alert">Copied to clipboard!</Alert>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EmployeeDetails;
