import React, { useState, useEffect } from "react";
import useCustomFetchQuery from "../../lib/Hooks/useCustomFecthQuery";
import { Container, Row, Col, Card, CardBody } from "reactstrap";

import withRouter from "../../components/Common/withRouter";
import Breadcrumb from "../../components/Common/Breadcrumb";
import ProfileDetails from "../../components/Pages/User-Profile/ProfileDetails";
import UserPersonalInfo from "../../components/Pages/User-Profile/UserPersonalInfo";
import UpdatePassword from "../../components/Pages/User-Profile/UpdatePassword";
import DeleteAccount from "../../components/Pages/User-Profile/DeleteAccount";
import LoaderUserProfile from "../../components/Loader/LoaderUserProfile";

const UserProfile = () => {
  document.title = "HC Kago | Profile";
  const obj = JSON.parse(localStorage.getItem("authHC"));
  const userData = obj?.data?.data;
  const userID = obj?.data?.data?.id;
  const userId = obj?.data?.data?.id;
  const token = obj?.token;

  const {
    data: profileInfo,
    error,
    isLoading,
  } = useCustomFetchQuery(
    ["profileInfo", userID],
    `${process.env.REACT_APP_APIKEY}users/${userID}`,
    token,
    { enabled: !!userID }
  );

  // console.log("profileInfo...", JSON.stringify(profileInfo, null, 2));

  // Simulate loading effect
  // useEffect(() => {
  //   // Simulate an API call or data fetching
  //   const timer = setTimeout(() => {
  //     setIsLoading(false); // Set loading to false after 2 seconds
  //   }, 2000);

  //   // Clean up the timer when the component unmounts
  //   return () => clearTimeout(timer);
  // }, []);

  if (isLoading) {
    return <LoaderUserProfile />;
  }

  return (
    <React.Fragment>
      <div
        className="page-content"
        style={{ userSelect: "none", background: "#F2F2F2" }}
      >
        <Container fluid>
          <Breadcrumb title="HC Kago" breadcrumbItem="Profile" />
          <Row>
            <Col md={2}></Col>
            <Col md={8} id="profile">
              <Card>
                <CardBody className="w-100 p-4" style={{ userSelect: "none" }}>
                  <ProfileDetails
                    userId={userId}
                    name={userData?.firstName}
                    lastName={userData?.lastName}
                    isEventOrganizer={false}
                    photo={userData?.photo}
                    userData={userData}
                    token={token}
                  />
                </CardBody>
              </Card>
              <Card>
                <CardBody
                  id="profile-details"
                  className="w-100 p-4"
                  style={{ userSelect: "none" }}
                >
                  <div className="h3 fw-bolder">Profile Details</div>
                  <UserPersonalInfo userData={userData} token={token} />
                </CardBody>
              </Card>

              <Card>
                <CardBody
                  id="password"
                  className="w-100 p-4"
                  style={{ userSelect: "none" }}
                >
                  <div className="h3 fw-bolder">Change Password</div>
                  <UpdatePassword userData={userData} token={token} />
                </CardBody>
              </Card>
              <Card>
                <CardBody
                  id="account"
                  className="w-100 p-4"
                  style={{ userSelect: "none" }}
                >
                  <div className="profile-form-title h3 fw-bolder">
                    Delete Account
                  </div>
                  <DeleteAccount userId={userId} token={token} />
                </CardBody>
              </Card>
            </Col>
            <Col md={2}></Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
