import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import logoKago from "../../../src/assets/images/kago-logo.png";
import usePost from "../../../src/lib/Hooks/usePost";

const Reset = (props) => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [responseMessage, setResponseMessage] = useState(null);



  // Initialize usePost hook
  const { execute, pending, data, status } = usePost();

  // Formik setup for form validation and submission
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Please enter a new password"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Please confirm your password"),
    }),
    onSubmit: (values) => {
      const Method = "PATCH";
      const endPoint = `auth/resetPassword/${token}`;

      const raw = JSON.stringify({
        password: values.newPassword,
        passwordConfirm: values.confirmPassword,
      });

      // Execute the usePost request
      execute(endPoint, raw, Method, "Password reset successful!");
    },
  });
  // Effect to handle success message
  useEffect(() => {
    if (status === "success") {
     navigate("/login");
    }
    
  }, [status]);

  return (
    <div className="account-pages my-5 pt-sm-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="overflow-hidden">
              <CardBody className="pt-0">
                <div className="d-flex justify-content-center">
                  <img src={logoKago} alt="Kago logo" height="34" />
                </div>
                <div className="p-2">
                  {responseMessage && (
                    <Alert color={status === "error" ? "danger" : "success"}>
                      {responseMessage}
                    </Alert>
                  )}
                  <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <div className="mb-3">
                      <Label>New Password</Label>
                      <Input
                        name="newPassword"
                        type="password"
                        placeholder="Enter new password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.newPassword || ""}
                        invalid={
                          validation.touched.newPassword &&
                          validation.errors.newPassword
                        }
                      />
                      {validation.touched.newPassword &&
                      validation.errors.newPassword ? (
                        <FormFeedback>{validation.errors.newPassword}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Confirm Password</Label>
                      <Input
                        name="confirmPassword"
                        type="password"
                        placeholder="Confirm new password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.confirmPassword || ""}
                        invalid={
                          validation.touched.confirmPassword &&
                          validation.errors.confirmPassword
                        }
                      />
                      {validation.touched.confirmPassword &&
                      validation.errors.confirmPassword ? (
                        <FormFeedback>{validation.errors.confirmPassword}</FormFeedback>
                      ) : null}
                    </div>
                    <Row className="mb-3">
                      <Col className="text-end">
                        <button
                          className="btn btn-primary w-md"
                          type="submit"
                          disabled={pending}
                        >
                          {pending ? "Resetting..." : "Reset Password"}
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Reset;
