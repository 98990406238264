import React, { useState, useEffect } from "react";
import useCustomMutation from "../../../../lib/Hooks/useCustomMutation";
import useCustomFetchQuery from "../../../../lib/Hooks/useCustomFecthQuery";
import { Progress, Card, CardBody } from "reactstrap";
import DataTable from "react-data-table-component";
import { LuUser2 } from "react-icons/lu";
import { AiFillEye } from "react-icons/ai";
// import { FaEdit } from "react-icons/fa";
import SearchInput from "../../../Common/SearchIput";
import ButtonSubmit from "../../../Common/Buttons/ButtonSubmit";
import { employeeTblTitle, employeeTbl } from "../../../Common/TableStyle";

import OffCanvas from "../../../Common/OffCanvas/OffCanvas";
import ManagerForm from "./ManagerForm";
// import EditManagerForm from "./EditManagerForm";
import EmployeeDetails from "../../../../Pages/People/Employees/EmployeeDetails";

const AllManagersTable = ({
  Navigate,
  token,
  organizationID,
  organizationDetails,
  departmentDetails,
  packageActivation,
}) => {
  const [search, setSearch] = useState("");
  const [employeeId, setEmployeeId] = useState(null);
  const [isAddProfile, setIsAddProfile] = useState(false);
  const [isEditProfile, setIsEditProfile] = useState(false);
  const [isViewProfile, setIsViewProfile] = useState(false);
  const [employeeDetail, setEmployeeDetail] = useState(null);

  const key = ["AllManagers", organizationID];
  const mutation = useCustomMutation(key);

  const {
    data: getManagers,
    error,
    isLoading,
  } = useCustomFetchQuery(
    ["AllManagers", organizationID],
    `${process.env.REACT_APP_APIKEY}employees?oid=${organizationID}&role=Manager`,
    token,
    { enabled: !!organizationID }
  );

  const filteredManagersData =
    getManagers?.data
      .filter((manager) =>
        manager?.roles?.some((role) => ["Manager"].includes(role?.type))
      )
      .sort((a, b) => a?.firstName?.localeCompare(b?.firstName)) || [];

  const toggleView = (manager) => {
    setIsViewProfile(!isViewProfile);
    setEmployeeDetail(manager);
    // Navigate(`/employee/${id}`);
  };
  const toggleEdit = (id) => {
    setEmployeeId(id);
    setIsEditProfile(!isEditProfile);
  };
  const toggleAddOffCanvas = () => {
    setIsAddProfile(!isAddProfile);
  };
  const toggleViewOffCanvas = () => {
    setIsViewProfile(!isViewProfile);
  };
  // const toggleEditOffCanvas = () => {
  //   setIsEditProfile(!isEditProfile);
  // };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Employee Name</span>,
      cell: (row) => {
        return (
          <div style={{ width: "100%" }}>
            <div className="w-100 d-flex align-items-center gap-2">
              <div
                className="d-flex justify-content-center align-items-center bg-primary"
                style={{ width: 35, height: 35, borderRadius: "50%" }}
              >
                {row?.photo ? (
                  <img
                    src={row?.photo}
                    alt="employee-pic"
                    className="w-100 h-100"
                    style={{ borderRadius: "50%" }}
                  />
                ) : (
                  <LuUser2 color="white" size={19} />
                )}
              </div>
              <div className="">
                <div style={{ fontSize: 14, fontWeight: 400 }}>
                  {row?.firstName} {row?.lastName}
                </div>
                <div
                  style={{ fontSize: 12, fontWeight: 500, color: "#A7A7A7" }}
                >
                  {row?.email}
                </div>
              </div>
            </div>
          </div>
        );
      },
    },

    {
      name: <span className="font-weight-bold fs-13">Department</span>,
      selector: (row) => row?.departmentId?.name,
    },
    {
      name: <span className="font-weight-bold fs-13">Role</span>,
      selector: (row) => row?.roles[0]?.type,
    },

    // {
    //   name: <span className="font-weight-bold fs-13">curriculum Progress</span>,
    //   cell: (row) => {
    //     return (
    //       <div className="w-100">
    //         {row?.curriculumProgress > 65 ? (
    //           <Progress
    //             color="success"
    //             value={row?.curriculumProgress}
    //           ></Progress>
    //         ) : row?.curriculumProgress > 50 && row?.curriculumProgress < 64 ? (
    //           <Progress
    //             color="warning"
    //             value={row?.curriculumProgress}
    //           ></Progress>
    //         ) : (
    //           <Progress
    //             color="danger"
    //             value={row?.curriculumProgress}
    //           ></Progress>
    //         )}
    //       </div>
    //     );
    //   },
    // },

    {
      name: <span className="font-weight-bold fs-13">Action</span>,

      cell: (row) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <AiFillEye
                size={20}
                className="mx-1"
                onClick={() => toggleView(row)}
              />
            </div>
            {/* <div>
              <FaEdit
                size={16}
                className="mx-1"
                onClick={() => toggleEdit(row?.id, row)}
              />
            </div> */}
          </div>
        );
      },
    },
  ];

  const filterEmployee = (managerdetails, searchQuery) => {
    if (!searchQuery) return managerdetails;
    return managerdetails?.filter((item) =>
      [item?.firstName, item?.lastName, item?.departmentId?.name].some(
        (field) => field?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  };
  const filteredEmployeeTable = filterEmployee(filteredManagersData, search);

  const data = filteredEmployeeTable || [];

  return (
    <div className="mt-3 mb-5 w-100">
      <div className="w-100 mb-3 d-flex justify-content-between">
        <SearchInput
          Title={"Search"}
          search={search}
          setSearch={setSearch}
          radius={20}
        />
        <div style={{ fontSize: 18, fontWeight: "bolder" }}>
          <ButtonSubmit
            Title="Add Manager"
            BackgroundColor="#33A6CD"
            ColorText="white"
            BorderColor="#33A6CD"
            borderRadius={20}
            handleOnclick={toggleAddOffCanvas}
            pending={false}
          />
        </div>
      </div>
      <Card style={employeeTbl}>
        <CardBody>
          <div style={employeeTblTitle}>Managers</div>
          <DataTable
            fixedHeader
            fixedHeaderScrollHeight="300px"
            columns={columns}
            responsive
            data={data}
            pagination
          />
        </CardBody>
      </Card>
      <OffCanvas
        isOffcanvasOpen={isAddProfile}
        title="Add Manager"
        body={
          <ManagerForm
            setIsAddProfile={setIsAddProfile}
            organizationID={organizationID}
            organizationDetails={organizationDetails}
            departmentDetails={departmentDetails}
          />
        }
        toggleSaveChanges={() => toggleAddOffCanvas()}
        directionOffCanvas="end"
        widthOffCanvas="100%"
      />
      <OffCanvas
        isOffcanvasOpen={isViewProfile}
        title="View Manager"
        body={<EmployeeDetails employeeDetails={employeeDetail} />}
        toggleSaveChanges={() => toggleViewOffCanvas()}
        directionOffCanvas="end"
        widthOffCanvas="100%"
      />
      {/* <OffCanvas
        isOffcanvasOpen={isEditProfile}
        title="Edit Manager"
        body={<EditManagerForm employeeId={employeeId} />}
        toggleSaveChanges={() => toggleEditOffCanvas()}
        directionOffCanvas="end"
        widthOffCanvas="100%"
      /> */}
    </div>
  );
};
export default AllManagersTable;
