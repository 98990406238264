import { useQuery } from "@tanstack/react-query";
import fetcher from "./fetcher";

// function useCustomFetchQuery(key, url, token, options = {}) {
//   return useQuery(key, () => fetcher(url, token), options);
// }
function useCustomFetchQuery(key, url, token, options = {}) {
  return useQuery(key, () => fetcher(url, token), {
    ...options,
    // Default to true if not specified to ensure backward compatibility
    enabled: options.enabled !== undefined ? options.enabled : true,
  });
}
export default useCustomFetchQuery;
