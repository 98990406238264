import React, { useState, useEffect } from "react";
import { Progress, Card, CardBody } from "reactstrap";
import DataTable from "react-data-table-component";
import { AiFillEye } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
// import SearchInput from "../../../Common/SearchIput";
import { employeeTblTitle, employeeTbl } from "../../../Common/TableStyle";

import OffCanvasFull from "../../../Common/OffCanvas/OffCanvasFull";
import { convertReviewsDate } from "../../../../lib/utils/convertReviewsDate";
import AssessEmployee from "../../Dashboard/Manager/AssessEmployee";
import ViewReview from "../../Dashboard/Employee/ViewReview";

//  const toggleView = (id) => {
//    Navigate(`/employee/${id}`);
//  };

const ManagerReviewTable = ({
  departmentReviews,
  organizationID,
  departmentID,
  userFullName,
  token,
}) => {
  const [search, setSearch] = useState("");
  const [reviewDetails, setReviewDetails] = useState(null);
  const [isViewEvaluation, setIsViewEvaluation] = useState(false);
  const [isCompleteEvatuation, setIsCompleteEvatuation] = useState(false);

  const toggleViewEvaluation = (details) => {
    setReviewDetails(details);
    setIsViewEvaluation(!isViewEvaluation);
  };

  const toggleCompleteEvaluation = (details) => {
    setReviewDetails(details);
    setIsCompleteEvatuation(!isCompleteEvatuation);
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Created Date</span>,
      selector: (row) => convertReviewsDate(row?.createdAt),
    },
    {
      name: <span className="font-weight-bold fs-13">Full name</span>,
      cell: (row) => {
        return (
          <div className="w-100">
            {row?.userId?.firstName} {row?.userId?.lastName}
          </div>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Email</span>,
      selector: (row) => row?.userId?.email,
    },
    {
      name: <span className="font-weight-bold fs-13">Phone number</span>,
      selector: (row) => row?.userId?.phoneNumber,
    },
    // {
    //   name: <span className="font-weight-bold fs-13">Role</span>,
    //   selector: (row) => row?.userId?.roles[0],
    // },

    // {
    //   name: <span className="font-weight-bold fs-13">Due Date</span>,
    //   selector: (row) => row?.averageScore,
    // },
    {
      name: <span className="font-weight-bold fs-13">Status</span>,
      cell: (row) => {
        return (
          <div className="w-100">
            <span
              className={`${
                row?.status === "Overdue"
                  ? `text-danger`
                  : row?.status === "In Review"
                  ? `text-warning`
                  : row?.status === "Not Signed"
                  ? `text-info`
                  : row?.status === "Submitted"
                  ? `text-success`
                  : row?.status === "Expired"
                  ? `text-dark`
                  : null
              }`}
              style={{
                width: 96,
                height: 32,
                borderRadius: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 13,
                fontWeight: "bolder",
                color: "#fefefe",
              }}
            >
              {row?.status === "In Review" ? "Pending" : row?.status}
              {/* {row?.status} */}
            </span>
          </div>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Score</span>,
      cell: (row) => {
        return (
          <div className="w-100">
            {row?.assessment?.manager?.total >= 0 &&
            row?.assessment?.manager?.total <= 49 ? (
              <Progress
                color="danger"
                value={row?.assessment?.manager?.total}
              ></Progress>
            ) : row?.assessment?.manager?.total >= 50 &&
              row?.assessment?.manager?.total <= 55 ? (
              <Progress
                color="warning"
                value={row?.assessment?.manager?.total}
              ></Progress>
            ) : (
              <Progress
                color="success"
                value={row?.assessment?.manager?.total}
              ></Progress>
            )}
          </div>
        );
      },
    },

    ,
    {
      name: <span className="font-weight-bold fs-13">Action</span>,

      cell: (row) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              {row?.status === "In Review" ? (
                <AiFillEye
                  size={20}
                  color="#D0D0D0"
                  className="mx-1"
                  onClick={() => {}}
                />
              ) : (
                <AiFillEye
                  size={20}
                  className="mx-1"
                  onClick={() => toggleViewEvaluation(row)}
                />
              )}
            </div>
            <div>
              {row?.status === "In Review" ? (
                <FaEdit
                  size={16}
                  className="mx-1"
                  onClick={() => toggleCompleteEvaluation(row)}
                />
              ) : (
                <FaEdit
                  size={16}
                  className="mx-1"
                  color="#D0D0D0"
                  onClick={() => {}}
                />
              )}
            </div>
          </div>
        );
      },
    },
  ];

  const filterEmployee = (employeeData, searchQuery) => {
    if (!searchQuery) return employeeData;
    return employeeData?.filter((item) =>
      [item?.fullName].some((field) =>
        field?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  };

  const filteredManagerData =
    departmentReviews?.data
      ?.filter((item) =>
        [
          "Overdue",
          "Submitted",
          "Expired",
          "In Review",
          "Not Signed",
        ]?.includes(item?.status)
      )
      ?.reverse() || [];

  const filteredEmployeeTable = filterEmployee(filteredManagerData, search);

  const data = filteredEmployeeTable || [];

  return (
    <div className="mt-3 mb-5 w-100">
      <Card>
        <CardBody style={employeeTbl}>
          <div style={employeeTblTitle}>Employee Review</div>
          <DataTable
            fixedHeader
            fixedHeaderScrollHeight="300px"
            columns={columns}
            responsive
            data={data}
            pagination
          />
        </CardBody>
      </Card>
      <OffCanvasFull
        isOffcanvasOpen={isViewEvaluation}
        title="View Evaluation Report"
        body={
          <ViewReview
            setIsViewEvaluation={setIsViewEvaluation}
            reviewDetails={reviewDetails}
          />
        }
        toggleSaveChanges={() => toggleViewEvaluation()}
        directionOffCanvas="end"
        widthOffCanvas={"100%"}
        bgColor={"#fff"}
        bgClose="#33A6CD"
        closeText={"#fff"}
      />
      <OffCanvasFull
        isOffcanvasOpen={isCompleteEvatuation}
        title=" Assess Employee"
        body={
          <AssessEmployee
            reviewDetails={reviewDetails}
            setIsCompleteEvatuation={setIsCompleteEvatuation}
            token={token}
            userFullName={userFullName}
            organizationID={organizationID}
            departmentID={departmentID}
          />
        }
        toggleSaveChanges={() => toggleCompleteEvaluation()}
        directionOffCanvas="end"
        widthOffCanvas={"100%"}
      />
    </div>
  );
};
export default ManagerReviewTable;
