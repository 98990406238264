import React from "react";

const GuideLine = () => {
  return (
    <>
      <div className="evaluation-view-form-left-card-container mt-3">
        <div className="evaluation-view-left-card-summary">
          <h4>Scoring System Guidelines</h4>
          <div className="w-100 d-flex justify-content-between">
            <div className="w-100 mt-2 d-flex justify-content-start">
              SECTIONS SCORED FROM 1 TO 5
            </div>
          </div>
          <div className="w-100 mt-2">
            <ol>
              <li>
                Score 1 (Poor/Unsatisfactory)
                <ul>
                  <li style={{ textAlign: "justify", wordSpacing: 0.3 }}>
                    The criterion has not been met at all or is severely
                    lacking. There are significant issues that need to be
                    addressed, and the result is not acceptable in its current
                    form.
                  </li>
                  <li style={{ textAlign: "justify", wordSpacing: 0.3 }}>
                    Example: "The quality of the work is below expectations,
                    with major flaws."
                  </li>
                </ul>
              </li>
              <li className="mt-2">
                Score 2 (Fair/Needs Improvement)
                <ul>
                  <li style={{ textAlign: "justify", wordSpacing: 0.1 }}>
                    The criterion is partially met, but there are notable
                    deficiencies. The result shows effort, but it falls short of
                    standards and needs significant improvement.
                  </li>
                  <li style={{ textAlign: "justify", wordSpacing: 0.1 }}>
                    Example: "The work is incomplete or lacks clarity, with
                    several important areas that need attention."
                  </li>
                </ul>
              </li>
              <li className="mt-2">
                Score 3 (Good/Satisfactory)
                <ul>
                  <li style={{ textAlign: "justify", wordSpacing: 0.1 }}>
                    The criterion is met with adequate performance. There is no
                    major issue, but there may be minor areas for improvement or
                    refinement. Overall, the result is acceptable and meets the
                    minimum standard.
                  </li>
                  <li style={{ textAlign: "justify", wordSpacing: 0.1 }}>
                    Example: "The work meets the expectations but could benefit
                    from some fine-tuning or further development."
                  </li>
                </ul>
              </li>
              <li className="mt-2">
                Score 4 (Very Good/Above Average)
                <ul>
                  <li style={{ textAlign: "justify", wordSpacing: 0.1 }}>
                    The criterion is well met, demonstrating a high level of
                    competence. The result is solid, with only minor suggestions
                    for improvement. The quality is clear, and it exceeds the
                    basic requirements.
                  </li>
                  <li style={{ textAlign: "justify", wordSpacing: 0.1 }}>
                    Example: "The work is of high quality, demonstrating a
                    strong grasp of the topic with minor improvements that could
                    be made."
                  </li>
                </ul>
              </li>
              <li className="mt-2">
                Score 5 (Excellent/Outstanding)
                <ul>
                  <li style={{ textAlign: "justify", wordSpacing: 0.1 }}>
                    The criterion is fully met or exceeded. The result is
                    exceptional and shows a clear mastery or innovation. There
                    are no significant weaknesses, and the work demonstrates a
                    high level of skill or quality.
                  </li>
                  <li style={{ textAlign: "justify", wordSpacing: 0.1 }}>
                    Example: "The work exceeds all expectations, demonstrating a
                    thorough understanding and an exceptional level of quality
                    or creativity."
                  </li>
                </ul>
              </li>
            </ol>
            <div></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GuideLine;
