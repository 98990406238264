import React from "react";
import { Offcanvas, OffcanvasHeader, OffcanvasBody } from "reactstrap";

const OffCanvasFull = ({
  isOffcanvasOpen,
  title,
  body,
  toggleSaveChanges,
  directionOffCanvas,
  widthOffCanvas,
}) => {
  return (
    <Offcanvas
      isOpen={isOffcanvasOpen}
      toggle={toggleSaveChanges}
      direction={directionOffCanvas}
      style={{ width: widthOffCanvas, backgroundColor: "#fff" }}
    >
      <OffcanvasHeader toggle={toggleSaveChanges}>
        <div className="d-flex justify-content-center h4">{title}</div>
      </OffcanvasHeader>
      <OffcanvasBody>
        <div
          style={{
            backgroundColor: "#fff",
          }}
        >
          <div style={{ width: "100%" }}>{body}</div>
        </div>
      </OffcanvasBody>
    </Offcanvas>
  );
};
export default OffCanvasFull;
