export default function submitReview(answers) {
  const outputData = {
    data: [
      {
        name: "Functional Skills",
        criteria: [
          {
            name: "Quality of Work",
            evaluations: [
              {
                attribute: "Accuracy, neatness and timeliness of work",
                score: answers?.qualityOfWork[0],
              },
              {
                attribute:
                  "Adherence to duties and procedures in Job Descriptions and Work Instructions",
                score: answers?.qualityOfWork[1],
              },
              {
                attribute:
                  "Synchronization with organizational/functional goals",
                score: answers?.qualityOfWork[2],
              },
            ],
          },
          {
            name: "Work Habits",
            evaluations: [
              {
                attribute: "Punctuality to workplace",
                score: answers?.workHabits[0],
              },
              {
                attribute: "Attendance",
                score: answers?.workHabits[1],
              },
              {
                attribute:
                  "Does the employee stay busy, look for things to do, initiates at workplace",
                score: answers?.workHabits[2],
              },
              {
                attribute: "Submits reports on time and meets deadlines",
                score: answers?.workHabits[3],
              },
            ],
          },
          {
            name: "Job Knowledge",
            evaluations: [
              {
                attribute: "Skill and ability to perform job satisfactorily",
                score: answers?.jobKnowledge[0],
              },
              {
                attribute: "Shown interest in learning and improving",
                score: answers?.jobKnowledge[1],
              },
              {
                attribute: "Problem-solving ability",
                score: answers?.jobKnowledge[2],
              },
            ],
          },
        ],
      },
      {
        name: "Interpersonal Skills",
        criteria: [
          {
            name: "Interpersonal relations/ behaviour",
            evaluations: [
              {
                attribute: "Responds and contributes to team efforts",
                score: answers?.interpersonalSkills[0],
              },
              {
                attribute:
                  "Responds positively to suggestions and instructions and criticism",
                score: answers?.interpersonalSkills[1],
              },
              {
                attribute: "Keeps supervisor informed of all details",
                score: answers?.interpersonalSkills[2],
              },
              {
                attribute: "Adapts well to changing circumstances",
                score: answers?.interpersonalSkills[3],
              },
              {
                attribute: "Seeks feedback to improve",
                score: answers?.interpersonalSkills[4],
              },
            ],
          },
        ],
      },
      {
        name: "Leadership Skills",
        criteria: [
          {
            name: "Leadership",
            evaluations: [
              {
                attribute:
                  "Aspirant to climb up the ladder, accepts challenges, new responsibilities and roles.",
                score: answers?.leadershipSkills[0],
              },
              {
                attribute:
                  "Innovative thinking - contribution to organizations and functions and personal growth.",
                score: answers?.leadershipSkills[1],
              },
              {
                attribute: "Work motivation.",
                score: answers?.leadershipSkills[2],
              },
            ],
          },
        ],
      },
    ],
    overall: [
      {
        attribute:
          "Employee performance and learning is unsatisfactory and is failing to improve at a satisfactory rate",
        value: answers?.overallProgress?.performanceFailing,
      },
      {
        attribute:
          "Employee performance and learning is acceptable and is improving at a satisfactory rate",
        value: answers?.overallProgress?.performanceAcceptable,
      },
      {
        attribute:
          "Employee has successfully demonstrated outstanding overall performance",
        value: answers?.overallProgress?.performanceOutstanding,
      },
    ],
    recommendation: answers?.overallProgress?.recommendation,
    comment: answers?.overallProgress?.comment,
  };

  return outputData;
}
