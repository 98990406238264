import React from "react";
import { LiaUser } from "react-icons/lia";

function ViewUsePicture({
  showImage,
  iconSize,
  iconColor,
  imgHeight,
  imgWidth,
  imgBorder,
  bgColor,
}) {
  return (
    <div className="w-100 d-flex justify-content-center">
      <div className="w-100 d-flex justify-content-center">
        <div
          style={{
            position: "relative",
            backgroundColor: bgColor ? bgColor : "#ededed",
            width: imgWidth,
            height: imgHeight,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: imgBorder,
            userSelect: "none",
          }}
        >
          {showImage ? (
            <img
              src={showImage}
              className="w-100 h-100"
              style={{ borderRadius: imgBorder }}
              alt="profile"
            />
          ) : (
            <LiaUser size={iconSize} color={iconColor} />
          )}
        </div>
      </div>
    </div>
  );
}

export default ViewUsePicture;
