import React, { useState, useEffect } from "react";
import { FaCheck } from "react-icons/fa";
import LoaderSubscriptions from "../../components/Loader/LoaderSubscriptions";

const MySubscription = () => {
  // State to manage loading
  const [isLoading, setIsLoading] = useState(true);

  // Simulate data fetching or API call
  useEffect(() => {
    // Simulate a delay, like an API call
    const timer = setTimeout(() => {
      setIsLoading(false); // Set loading to false after the delay
    }, 2000); // Adjust the time as needed

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return (
      <div className="page-content w-100 d-flex justify-content-center">
        <div style={{ width: "70%", margin: "0 auto" }}>
          <LoaderSubscriptions />
        </div>
      </div>
    );
  }

  const styles = {
    container: {
      maxWidth: "800px",
      padding: "24px",
    },
    title: {
      fontSize: "20px",
      fontWeight: "600",
      marginBottom: "24px",
    },
    plansContainer: {
      display: "flex",
      gap: "16px",
      marginBottom: "24px",
    },
    planCard: {
      flex: "1",
      padding: "16px",
      borderRadius: "8px",
      border: "1px solid #33a6cd",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      minHeight: "160px",
      userSelect: "none",
    },
    selectedPlan: {
      border: "1px solid #33a6cd",
    },
    cardIcon: {
      position: "absolute",
      top: "-8px",
      right: "-8px",
      backgroundColor: "#33a6cd",
      borderRadius: "50%",
      padding: "2px",
      color: "white",
      width: "25px",
      height: "25px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    contactUsPlan: {
      background: `linear-gradient(to right, #33a6cd, #6FBFE0)`,
      color: "white",
      border: "none",
    },
    planHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "16px",
    },
    planName: {
      fontWeight: "500",
    },
    price: {
      display: "flex",
      alignItems: "center",
      gap: "4px",
    },
    priceAmount: {
      fontSize: "18px",
      fontWeight: "600",
    },
    priceInterval: {
      fontSize: "14px",
      color: "#6b7280",
    },
    activationNotice: {
      fontSize: "14px",
      color: "#6b7280",
      marginBottom: "16px",
    },
    whiteText: {
      color: "white",
    },
    button: {
      padding: "8px 16px",
      borderRadius: "6px",
      fontSize: "14px",
      fontWeight: "500",
      cursor: "pointer",
      border: "none",
      background: "none",
    },
    cancelButton: {
      width: "180px",
      height: "30px",
      color: "#ff0000",
      backgroundColor: "#fff",
      textAlign: "center",
      padding: "0px 0px 0px 4px",
      borderStyle: "solid",
      border: "2px solid #ff0000",
    },
    upgradeButton: {
      width: "180px",
      height: "30px",
      backgroundColor: "white",
      color: "#33a6cd",
      marginRight: "12px",
      borderRadius: 5,
      borderStyle: "none",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
    },
    toggleDescription: {
      fontSize: "14px",
      color: "#6b7280",
      marginTop: "8px",
    },
  };

  return (
    <div className="page-content w-100 d-flex justify-content-center">
      <div style={styles.container}>
        <h2 style={styles.title}>Plan</h2>

        <div style={styles.plansContainer}>
          {/* Current Plan */}
          <div style={{ ...styles.planCard, ...styles.selectedPlan }}>
            <div style={styles.cardIcon}>
              <FaCheck size={15} color="white" />
            </div>

            <div>
              <div style={styles.planHeader}>
                <span style={styles.planName}>Current Plan</span>
                <div style={styles.price}>
                  <span style={styles.priceAmount}>R100</span>
                  <span style={styles.priceInterval}>/month</span>
                </div>
              </div>
              <div style={styles.activationNotice}>Your package is active</div>
            </div>
            <button style={styles.cancelButton}>Cancel Subscription</button>
          </div>

          {/* Contact Us */}
          <div style={{ ...styles.planCard, ...styles.contactUsPlan }}>
            <div>
              <div style={styles.planHeader}>
                <span
                  style={{
                    ...styles.planName,
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                >
                  Contact Us
                </span>
              </div>
              <div style={{ ...styles.activationNotice, ...styles.whiteText }}>
                Want to update your subscription? Please click below.
              </div>
            </div>
            <div style={styles.buttonContainer}>
              <button style={styles.upgradeButton}>Upgrade</button>
            </div>
          </div>
        </div>

        <p style={styles.toggleDescription}>
          This option, if checked, will renew your subscription automatically
          when the current plan expires.
        </p>
      </div>
    </div>
  );
};

export default MySubscription;
