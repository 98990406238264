import React, { useState, useRef, useEffect } from "react";
import useCustomMutation from "../../../../lib/Hooks/useCustomMutation";
import { Row, Col } from "reactstrap";
import SignatureCanvas from "react-signature-canvas";
import Summary from "./Summary";
import GuideLine from "./GuideLine";
import { MdDeleteForever } from "react-icons/md";
import { IoMdSave } from "react-icons/io";
import { IoImageOutline } from "react-icons/io5";
import "./completeReview.css";

// Function to extract data for each section
const getSectionData = (sectionName, reviewDetails) => {
  const sectionData = reviewDetails?.assessment?.manager?.data;

  console.log(reviewDetails, null, 2);

  // Look within "Functional Skills" and other main categories for the specified section
  const functionalSkills = sectionData?.find(
    (item) => item.name === "Functional Skills"
  );

  const criteria =
    functionalSkills?.criteria?.find(
      (criterion) => criterion?.name === sectionName
    ) ||
    sectionData?.find((section) => section?.name === sectionName)?.criteria[0];

  return (
    criteria?.evaluations?.map((evaluation) => ({
      attribute: evaluation?.attribute,
      score: evaluation?.score,
    })) || []
  );
};

// Question Component
const Question = ({ questionText, selectedValue, choices = [] }) => {
  return (
    <div className="question">
      <p>{questionText}</p>
      <div className="choices gap-3">
        {choices?.map((choice) => (
          <button
            key={choice}
            className={`choice-button ${
              selectedValue === choice ? "active" : ""
            }`}
            disabled
          >
            {choice}
          </button>
        ))}
      </div>
    </div>
  );
};

// Section Component
const Section = ({ sectionTitle, questions, choicesPerQuestion }) => {
  return (
    <div className="section">
      <h3>{sectionTitle}</h3>
      {questions?.map((question, index) => (
        <Question
          key={index}
          questionText={question.attribute}
          selectedValue={question?.score}
          choices={choicesPerQuestion[index]}
        />
      ))}
    </div>
  );
};

const SignReview = ({
  reviewDetails,
  setIsSignEvaluation,
  organizationID,
  token,
}) => {
  const overallProgress = reviewDetails?.assessment?.manager?.overall || [];
  const key = ["MyReviews", organizationID];
  const Mutation = useCustomMutation(key);

  const [image, setImage] = useState(null);
  const sigPadRef = useRef(null);
  const fileInputRef = useRef(null);

  const clearSignature = () => {
    if (sigPadRef.current) {
      sigPadRef.current.clear();
    }
    setImage(null);
  };

  const saveSignature = () => {
    if (image) {
    } else if (sigPadRef.current && !sigPadRef.current.isEmpty()) {
      setImage(sigPadRef.current.getTrimmedCanvas().toDataURL("image/png"));
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => setImage(e.target.result);
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const Method = "PATCH",
      url = `assessments/${reviewDetails?._id}`;

    const raw = JSON.stringify({
      employeeSignature: image,
      status: "Submitted",
    });

    const data = {
      endPoint: url,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Review successfully signed",
      token: token,
    };
    Mutation.mutate(data);
  };
  useEffect(() => {
    if (Mutation?.isSuccess) {
      setIsSignEvaluation(false);
    }
  }, [Mutation?.isSuccess]);

  return (
    <div className="evaluation-view-form-container">
      <Row>
        <Col md={12} lg={8}>
          <div className="evaluation-review-form">
            {/* Quality of Work Section */}
            <Section
              sectionTitle="Quality of Work (Out of 15 Marks)"
              questions={getSectionData("Quality of Work", reviewDetails)}
              choicesPerQuestion={[
                [1, 2, 3, 4, 5],
                [1, 2, 3, 4, 5],
                [1, 2, 3, 4, 5],
              ]}
            />

            {/* Work Habits Section */}
            <Section
              sectionTitle="Work Habits (Out of 20 Marks)"
              questions={getSectionData("Work Habits", reviewDetails)}
              choicesPerQuestion={[
                [1, 2, 3, 4, 5],
                [1, 2, 3, 4, 5],
                [1, 2, 3, 4, 5],
                [1, 2, 3, 4, 5],
              ]}
            />

            {/* Job Knowledge Section */}
            <Section
              sectionTitle="Job Knowledge (Out of 15 Marks)"
              questions={getSectionData("Job Knowledge", reviewDetails)}
              choicesPerQuestion={[
                [1, 2, 3, 4, 5],
                [1, 2, 3, 4, 5],
                [1, 2, 3, 4, 5],
              ]}
            />

            {/* Interpersonal Skills Section */}
            <Section
              sectionTitle="Interpersonal Skills (Out of 25 Marks)"
              questions={getSectionData("Interpersonal Skills", reviewDetails)}
              choicesPerQuestion={[
                [1, 2, 3, 4, 5],
                [1, 2, 3, 4, 5],
                [1, 2, 3, 4, 5],
                [1, 2, 3, 4, 5],
                [1, 2, 3, 4, 5],
              ]}
            />

            {/* Leadership Skills Section */}
            <Section
              sectionTitle="Leadership Skills (Out of 25 Marks)"
              questions={getSectionData("Leadership Skills", reviewDetails)}
              choicesPerQuestion={[
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                [1, 2, 3, 4, 5],
              ]}
            />

            {/* Overall Progress Section */}
            <div className="section">
              <h3>Overall Progress</h3>
              {overallProgress.map((progress, index) => (
                <div key={index} className="question">
                  <p>{progress.attribute}</p>
                  <div className="choices gap-3">
                    <button
                      className={`choice-button ${
                        progress.value === "Yes" ? "active" : ""
                      }`}
                      disabled
                    >
                      Yes
                    </button>
                    <button
                      className={`choice-button ${
                        progress.value === "No" ? "active" : ""
                      }`}
                      disabled
                    >
                      No
                    </button>
                  </div>
                </div>
              ))}
            </div>

            {/* Recommendations & Final Comments Section */}
            <div className="section">
              <h3>Comment & Recommendation</h3>
              <div className="question">
                <label>Employee Comment</label>
                <textarea
                  readOnly
                  value={reviewDetails?.assessment?.employee?.comment || ""}
                  className="w-100 p-2 rounded-3"
                  rows="4"
                />
              </div>
              <div className="question">
                <label>Manager Recommendation</label>
                <textarea
                  readOnly
                  value={
                    reviewDetails?.assessment?.manager?.recommendation || ""
                  }
                  className="w-100 p-2 rounded-3"
                  rows="4"
                />
              </div>

              <div className="w-100 d-flex justify-content-between">
                <div className="w-100">
                  <label>Manager Signature</label>
                  <div>
                    {reviewDetails?.managerSignature ? (
                      <img
                        src={reviewDetails?.managerSignature}
                        alt="manager-signature"
                        style={{ width: 100, height: 50, objectFit: "contain" }}
                      />
                    ) : (
                      <div className="text-danger">
                        Manager signature is required
                      </div>
                    )}
                  </div>
                </div>
                <div className="w-100 d-flex justify-content-end">
                  <div>
                    <div className="w-100 d-flex justify-content-end">
                      <label>Employee Signature</label>
                    </div>

                    {reviewDetails?.employeeSignature ? (
                      <img
                        src={reviewDetails?.employeeSignature}
                        alt="employee-signature"
                        style={{ width: 100, height: 50, objectFit: "contain" }}
                      />
                    ) : (
                      <>
                        {!image ? (
                          <div className="w-100 d-flex justify-content-end text-danger">
                            Sign below
                          </div>
                        ) : null}

                        <div className="signature-container">
                          {image ? (
                            <img
                              src={image}
                              alt="Signature Preview"
                              className="signature-preview"
                            />
                          ) : (
                            <SignatureCanvas
                              ref={sigPadRef}
                              penColor="#33a6cd"
                              canvasProps={{
                                className: "sigCanvas",
                                width: 200,
                                height: 100,
                              }}
                            />
                          )}
                          <div className="signature-btn-container">
                            <MdDeleteForever
                              size={20}
                              color="#ff0000"
                              onClick={clearSignature}
                            />
                            <br />{" "}
                            <div className="mt-2">
                              <IoMdSave
                                size={16}
                                color="#1A8F24"
                                onClick={saveSignature}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="signature-upload-photo">
                          <button
                            onClick={() => fileInputRef?.current?.click()}
                            className="signature-upload-photo-btn"
                          >
                            <IoImageOutline size={20} color="#33a6cd" />
                            <span
                              className="mx-2"
                              style={{ textDecoration: "underline" }}
                            >
                              Upload image
                            </span>
                          </button>
                          <input
                            ref={fileInputRef}
                            type="file"
                            accept="image/*"
                            onChange={handleImageUpload}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-100 mt-3 mb-5 d-flex justify-content-center">
                {image ? (
                  <button className="submit-button" onClick={handleSubmit}>
                    {Mutation?.isLoading ? "...Loading" : "Submit"}
                  </button>
                ) : (
                  <button className="back-button" onClick={() => {}}>
                    Submit
                  </button>
                )}
              </div>
            </div>
          </div>
        </Col>
        <Col md={12} lg={4}>
          <Summary result={reviewDetails?.assessment?.manager} />
          <GuideLine />
        </Col>
      </Row>
    </div>
  );
};

export default SignReview;
