import React, { useState, useRef, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

const OverallPerformanceChart = ({
  value,
  min = 0,
  max = 100,
  width = 200,
  height = 200,
  thickness = 0.35,
  lineCap = "butt",
  font = "Arial",
  fontWeight = "bold",
  clockwise = true,
  angleArc = 360, // Full circle arc by default
  angleOffset = 0,
  title,
  className = null,
  canvasClassName = null,
}) => {
  const canvasRef = useRef(null);
  const [dimensions] = useState({
    w: width,
    h: height || width,
  });

  const angleArcInRadians = (angleArc * Math.PI) / 180;
  const angleOffsetInRadians = (angleOffset * Math.PI) / 180;
  const startAngle = 1.5 * Math.PI + angleOffsetInRadians;

  // Function to calculate the end angle based on the value
  const getArcToValue = (v) => {
    const angle = ((v - min) / (max - min)) * angleArcInRadians;
    return {
      startAngle: startAngle,
      endAngle: startAngle + angle,
      acw: !clockwise,
    };
  };

  // Determine the foreground color based on the value
  const getFgColor = () => {
    if (value >= 0 && value <= 49) {
      return "#ff3d60"; // danger color
    } else if (value >= 50 && value <= 55) {
      return "#fcb92c"; // warning color
    } else if (value > 55) {
      return "#0ac074"; // success color
    }
    return "gray"; // default color
  };

  useEffect(() => {
    drawCanvas();
  }, [value, dimensions]);

  const getCanvasScale = (ctx) => {
    const devicePixelRatio =
      window.devicePixelRatio ||
      window.screen.deviceXDPI / window.screen.logicalXDPI ||
      1;
    const backingStoreRatio = ctx.webkitBackingStorePixelRatio || 1;
    return devicePixelRatio / backingStoreRatio;
  };

  const drawCanvas = useCallback(() => {
    const ctx = canvasRef.current.getContext("2d");
    const scale = getCanvasScale(ctx);
    canvasRef.current.width = dimensions.w * scale;
    canvasRef.current.height = dimensions.h * scale;
    ctx.scale(scale, scale);
    const xy = dimensions.w / 2;
    const lineWidth = xy * thickness;
    const radius = xy - lineWidth / 2;
    ctx.lineWidth = lineWidth;
    ctx.lineCap = lineCap;

    // background arc
    ctx.beginPath();
    ctx.strokeStyle = "#EEE"; // Background color
    ctx.arc(
      xy,
      xy,
      radius,
      startAngle,
      startAngle + angleArcInRadians,
      !clockwise
    );
    ctx.stroke();

    // foreground arc
    const a = getArcToValue(value);
    ctx.beginPath();
    ctx.strokeStyle = getFgColor();
    ctx.arc(xy, xy, radius, a.startAngle, a.endAngle, a.acw);
    ctx.stroke();

    // Draw the value inside the arc
    ctx.font = `${fontWeight} ${dimensions.w / 5}px ${font}`;
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillStyle = getFgColor();
    ctx.fillText(value, xy, xy);
  }, [value, dimensions]);

  return (
    <div
      className={className}
      style={{
        width: dimensions.w,
        height: dimensions.h,
        display: "inline-block",
      }}
    >
      <canvas
        ref={canvasRef}
        className={canvasClassName}
        style={{ width: "100%", height: "100%" }}
        title={title ? `${title}: ${value}` : value}
      />
    </div>
  );
};

OverallPerformanceChart.propTypes = {
  value: PropTypes.number.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  thickness: PropTypes.number,
  lineCap: PropTypes.oneOf(["butt", "round"]),
  font: PropTypes.string,
  fontWeight: PropTypes.string,
  clockwise: PropTypes.bool,
  angleArc: PropTypes.number,
  angleOffset: PropTypes.number,
  title: PropTypes.string,
  className: PropTypes.string,
  canvasClassName: PropTypes.string,
};

export default OverallPerformanceChart;
