import React, { useState, useEffect } from "react";
import useCustomFetchQuery from "../../../../lib/Hooks/useCustomFecthQuery";
import { Progress, Card, CardBody } from "reactstrap";
import DataTable from "react-data-table-component";
import OffCanvasFull from "../../../Common/OffCanvas/OffCanvasFull";
import { AiFillEye } from "react-icons/ai";
import { LuUser2 } from "react-icons/lu";
// import { FaEdit } from "react-icons/fa";
import { employeeTblTitle, employeeTbl } from "./Style";
import { convertReviewsDate } from "../../../../lib/utils/convertReviewsDate";
import AssessEmployee from "./AssessEmployee";

const EmployeeDepartmentTable = ({
  Navigate,
  userID,
  token,
  userFullName,
  organizationID,
  departmentID,
  search,
}) => {
  const [reviewDetails, setReviewDetails] = useState(null);
  const [isCompleteEvatuation, setIsCompleteEvatuation] = useState(false);

  const {
    data: departmentEmployee,
    error,
    isLoading,
  } = useCustomFetchQuery(
    ["EmployeeBydept", organizationID],
    `${process.env.REACT_APP_APIKEY}employees?did=${departmentID}`,
    token,
    { enabled: !!organizationID }
  );

  const filteredEmployeesData =
    departmentEmployee?.data
      .filter((employee) =>
        employee?.roles?.some((role) =>
          ["Learner", "Intern", "Employee"].includes(role?.type)
        )
      )
      .sort((a, b) => a?.firstName?.localeCompare(b?.firstName)) || [];

  console.log(
    "departmentEmployee...",
    JSON.stringify(departmentEmployee, null, 2)
  );
  const toggleViewReview = (id) => {
    Navigate(`/employee/${id}`);
  };

  const toggleCompleteEvaluation = (details) => {
    setReviewDetails(details);
    setIsCompleteEvatuation(!isCompleteEvatuation);
  };
  const toggleAction = (id) => {
    Navigate(`/employee/${id}`);
  };
  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Employee Name</span>,
      cell: (row) => {
        return (
          <div style={{ width: "100%" }}>
            <div className="w-100 d-flex align-items-center gap-2">
              <div
                className="d-flex justify-content-center align-items-center bg-primary"
                style={{ width: 35, height: 35, borderRadius: "50%" }}
              >
                {row?.photo ? (
                  <img
                    src={row?.photo}
                    alt="employee-pic"
                    className="w-100 h-100"
                    style={{ borderRadius: "50%" }}
                  />
                ) : (
                  <LuUser2 color="white" size={19} />
                )}
              </div>
              <div className="">
                <div style={{ fontSize: 14, fontWeight: 400 }}>
                  {row?.firstName} {row?.lastName}
                </div>
                <div
                  style={{ fontSize: 12, fontWeight: 500, color: "#A7A7A7" }}
                >
                  {row?.email}
                </div>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Department</span>,
      selector: (row) => row?.departmentId?.name,
    },
    {
      name: <span className="font-weight-bold fs-13">Role</span>,
      selector: (row) => row?.roles[0]?.type,
    },
    // {
    //   name: <span className="font-weight-bold fs-13">curriculum Progress</span>,
    //   cell: (row) => {
    //     return (
    //       <div className="w-100">
    //         {row?.curriculumProgress > 56 ? (
    //           <Progress
    //             color="warning"
    //             value={row?.curriculumProgress}
    //           ></Progress>
    //         ) : (
    //           <Progress
    //             color="success"
    //             value={row?.curriculumProgress}
    //           ></Progress>
    //         )}
    //       </div>
    //     );
    //   },
    // },

    {
      name: <span className="font-weight-bold fs-13">Action</span>,

      cell: (row) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <AiFillEye
                size={20}
                className="mx-1"
                onClick={() => toggleAction(row?.id)}
              />
            </div>
          </div>
        );
      },
    },
  ];

  const filterEmployee = (employeeData, searchQuery) => {
    if (!searchQuery) return employeeData;
    return employeeData?.filter((item) =>
      [item?.fullName].some((field) =>
        field?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  };

  const filteredEmployeeTable = filterEmployee(
    departmentEmployee?.data,
    search
  );

  const data = filteredEmployeeTable || [];

  return (
    <div className="mt-3 mb-5 w-100">
      <Card>
        <CardBody style={employeeTbl}>
          <div style={employeeTblTitle}>Average score per employee</div>
          <DataTable
            fixedHeader
            fixedHeaderScrollHeight="300px"
            columns={columns}
            responsive
            data={data}
            pagination
          />
        </CardBody>
      </Card>

      <OffCanvasFull
        isOffcanvasOpen={isCompleteEvatuation}
        title=" Assess Employee"
        body={
          <AssessEmployee
            reviewDetails={reviewDetails}
            setIsCompleteEvatuation={setIsCompleteEvatuation}
            token={token}
            userID={userID}
            userFullName={userFullName}
            organizationID={organizationID}
            departmentID={departmentID}
          />
        }
        toggleSaveChanges={() => toggleCompleteEvaluation()}
        directionOffCanvas="end"
        widthOffCanvas={"100%"}
      />
    </div>
  );
};
export default EmployeeDepartmentTable;
