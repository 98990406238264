import React from "react";
import useCustomFetchQuery from "../../../lib/Hooks/useCustomFecthQuery";
import { useNavigate } from "react-router-dom";
import { Container } from "reactstrap";
import AllEmployeesTable from "../../../components/Pages/People/AllEmployee/AllEmployeesTable";
import LoaderEmployees from "../../../components/Loader/LoaderEmployees";

const AllEmployees = () => {
  const Navigate = useNavigate();
  const obj = JSON.parse(localStorage.getItem("authHC"));
  const token = obj?.token;
  const organizationID = obj?.data?.data?.organizationId;

  const {
    data: organizationDetails,
    error,
    isLoading,
  } = useCustomFetchQuery(
    ["CompanyDetails", organizationID],
    `${process.env.REACT_APP_APIKEY}organizations/${organizationID}`,
    token,
    { enabled: !!organizationID }
  );

  const {
    data: departmentDetails,
    error: errorDepartmentDetails,
    isLoading: isLoadingDepartmentDetails,
  } = useCustomFetchQuery(
    ["AllDepartments", organizationID],
    `${process.env.REACT_APP_APIKEY}departments/${organizationID}/organization`,
    token,
    { enabled: !!organizationID }
  );

  const packageActivation = organizationDetails?.data?.status;

  if (isLoadingDepartmentDetails) {
    return (
      <div className="page-content">
        <LoaderEmployees />
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <AllEmployeesTable
            Navigate={Navigate}
            token={token}
            organizationID={organizationID}
            organizationDetails={organizationDetails?.data}
            departmentDetails={departmentDetails?.data}
            packageActivation={packageActivation}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AllEmployees;
