import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import AdminDashboard from "./Admin/Admin";
import ManagerDashboard from "./Manager/Manager";
import EmployeeDashboard from "./Employee/Employee";
import SearchInput from "../../components/Common/SearchIput";
import { getFormattedDate } from "../../lib/utils/getTodayDate";
import LoaderDashboard from "../../components/Loader/LoaderDashboard";

const Dashboard = () => {
  document.title = "Dashboard | Kago Human Capital";
  const obj = JSON.parse(localStorage.getItem("authHC"));
  const token = obj?.token;
  const userID = obj?.data?.data?.id;
  const firstName = obj?.data?.data?.firstName;
  const lastName = obj?.data?.data?.lastName;
  const userFullName = `${firstName} ${lastName}`;
  const role = obj?.data?.data?.roles[0];
  const organizationID = obj?.data?.data?.organizationId;
  const departmentID = obj?.data?.data?.departmentId?._id;
  const Navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true); // State to manage loading
  const todayDate = getFormattedDate();

  // console.log("departmentID...", JSON.stringify(departmentID?._id, null, 2));

  useEffect(() => {
    // Simulate loading data
    const fetchData = async () => {
      // Simulate a delay for loading (e.g., API call)
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setLoading(false); // Set loading to false after data is loaded
    };

    fetchData();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <div className="w-100 mb-3 d-flex justify-content-between">
            <SearchInput
              Title={"Search"}
              search={search}
              setSearch={setSearch}
              radius={20}
            />
            <div style={{ fontSize: 18, fontWeight: "bolder" }}>
              {todayDate}
            </div>
          </div>
          <div className="w-100">
            {loading ? (
              <LoaderDashboard />
            ) : role?.type === "Admin" ? (
              <AdminDashboard
                Navigate={Navigate}
                token={token}
                organizationID={organizationID}
              />
            ) : role?.type === "Manager" ? (
              <ManagerDashboard
                Navigate={Navigate}
                userID={userID}
                userFullName={userFullName}
                token={token}
                organizationID={organizationID}
                departmentID={departmentID}
              />
            ) : role?.type === "Employee" ? (
              <EmployeeDashboard
                userID={userID}
                token={token}
                organizationID={organizationID}
                departmentID={departmentID}
              />
            ) : role?.type === "Intern" ? (
              <EmployeeDashboard
                userID={userID}
                token={token}
                organizationID={organizationID}
                departmentID={departmentID}
              />
            ) : role?.type === "Learner" ? (
              <EmployeeDashboard
                userID={userID}
                token={token}
                organizationID={organizationID}
                departmentID={departmentID}
              />
            ) : null}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
