import React from "react";
import ReactStars from "react-stars";

const Summary = ({ result }) => {
  const totalScoring = result?.total;
  const functionalSkills = result?.data[0]?.total;
  const qualityOfWork = result?.data[0]?.criteria[0]?.total;
  const workHabits = result?.data[0]?.criteria[1]?.total;
  const jobKnowledge = result?.data[0]?.criteria[2]?.total;
  const interpersonalSkills = result?.data[1]?.criteria[0]?.total;
  const leadershipSkills = result?.data[2]?.criteria[0]?.total;

  const systemScore = (totalScoring / 20)?.toFixed(1);

  const getRating = (score) => {
    if (score < 2)
      return { stars: 1, text: "Unsatisfactory", className: "#ff0000" };
    if (score < 3)
      return {
        stars: 2,
        text: "Needs Improvement",
        className: "#fcb92c",
      };
    if (score < 4) return { stars: 3, text: "Good", className: "#33a6cd" };
    if (score < 5) return { stars: 4, text: "Very Good", className: "#08b36c" };
    return { stars: 5, text: "Excellent", className: "#08b36c" };
  };

  const { stars, text, className } = getRating(parseFloat(systemScore));

  return (
    <>
      <div className="evaluation-view-form-left-card-container">
        <div className="evaluation-view-left-card-summary">
          <h3>Summary</h3>
          <div className="scoring-summary-title">
            <div className="w-100 d-flex justify-content-start">
              TOTAL SCORING
            </div>
            <div className="w-100 d-flex justify-content-end">
              <span>{totalScoring}</span>
              <span className="mx-1">/</span>
              <span>100</span>
            </div>
          </div>
          <div className="mt-2 scoring-summary-subTitle">
            <div className="w-100  d-flex justify-content-start">
              FUNCTIONAL SKILLS
            </div>
            <div className="w-100 d-flex justify-content-end">
              <span>{functionalSkills}</span>
              <span className="mx-1">/</span>
              <span>50</span>
            </div>
          </div>
          <div style={{ paddingLeft: 6 }}>
            <div className="w-100 d-flex justify-content-between">
              <div className="w-100 d-flex justify-content-start">
                Quality of Work
              </div>
              <div className="w-100 d-flex justify-content-end">
                <span>{qualityOfWork}</span>
                <span className="mx-1">/</span>
                <span>15</span>
              </div>
            </div>
            <div className="w-100 d-flex justify-content-between">
              <div className="w-100 d-flex justify-content-start">
                Work Habits
              </div>
              <div className="w-100 d-flex justify-content-end">
                <span>{workHabits}</span>
                <span className="mx-1">/</span>
                <span>20</span>
              </div>
            </div>
            <div className="w-100 d-flex justify-content-between">
              <div className="w-100 d-flex justify-content-start">
                Job Knowledge
              </div>
              <div className="w-100 d-flex justify-content-end">
                <span>{jobKnowledge}</span>
                <span className="mx-1">/</span>
                <span>15</span>
              </div>
            </div>
          </div>
          <div className="mt-2 scoring-summary-subTitle">
            <div className="w-100  d-flex justify-content-start">
              INTERPERSONAL SKILLS
            </div>
            <div className="w-100 d-flex justify-content-end">
              <span>{interpersonalSkills}</span>
              <span className="mx-1">/</span>
              <span>25</span>
            </div>
          </div>
          <div className="mt-2 scoring-summary-subTitle">
            <div className="w-100  d-flex justify-content-start">
              LEADERSHIP SKILLS
            </div>
            <div className="w-100 d-flex justify-content-end">
              <span>{leadershipSkills}</span>
              <span className="mx-1">/</span>
              <span>25</span>
            </div>
          </div>
          <div className="mt-3 scoring-summary-title">
            <div className="w-100  d-flex justify-content-start ">
              SYSTEM SCORING
            </div>
            <div className="w-100 d-flex justify-content-end ">
              <span>{systemScore}</span> <span className="mx-1">/</span>
              <span>5</span>
            </div>
          </div>
          <div className="w-100 d-flex justify-content-between align-items-center">
            <div className="w-100  d-flex justify-content-start ">
              <ReactStars
                count={5}
                value={systemScore}
                size={24}
                color2={className}
                edit={false}
              />
            </div>
            <div
              className={`w-100 mt-1 d-flex justify-content-end scoring-summary-subTitle ${className}`}
              style={{ color: className }}
            >
              {text}!
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Summary;
