import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import OffCanvas from "../../components/Common/OffCanvas/OffCanvas";
import ContactUs from "../../components/Common/Pricing/ContactUs";
import logo from "../../assets/images/kago-hc-header.png";
import "./pricingStyle.scss";

const PricingPage = () => {
  const Navigate = useNavigate();
  const [isAnnual, setIsAnnual] = useState(true);
  const [istoggleContact, setIstoggleContact] = useState(false);

  const pricing = {
    packageID: "6720ac830bd2709cc7052d5e",
    starter: isAnnual ? 100 : 50,
    pro: isAnnual ? 200 : 80,
    enterprise: "Custom",
  };

  // Get the current year dynamically

  const currentYear = new Date().getFullYear();

  const handleSubscribe = (plan) => {
    // const price = pricing[plan];
    // const packageName = plan.charAt(0).toUpperCase() + plan.slice(1);
    const packageID = "6720ac830bd2709cc7052d5e";

    // localStorage.setItem(
    //   "subscriptionHC",
    //   JSON.stringify({
    //     pID: packageID,
    //     price: price,
    //     packageName: packageName,
    //     packageDuration: isAnnual ? "Annual" : "Monthly",
    //   })
    // );

    localStorage.setItem(
      "subscriptionHC",
      JSON.stringify({
        pID: packageID,
      })
    );

    // Navigate(`/register`);
    Navigate(`/create-account`);
  };

  return (
    <div className="pricing-page">
      <nav className="navbar-pricing">
        <div className="logo-pricing">
          <img src={logo} className="w-100 h-100" />
        </div>
        <ul className="nav-links-pricing"></ul>
      </nav>

      <div className="banner-pricing">
        <h1>Plans and Pricing</h1>
        <p>
          Receive unlimited credits when you pay yearly, and save on your plan.
        </p>
        <p>
          Choose a plan that best suits your event goals and scale and host
          effective virtual, hybrid, and in-person events.
        </p>
      </div>

      <div className="w-100 d-flex justify-content-center">
        <div className="billing-switch-pricing">
          <div
            className={`billing-option ${!isAnnual ? "selected" : ""}`}
            onClick={() => setIsAnnual(false)}
          >
            Monthly
          </div>
          <div
            className={`billing-option ${isAnnual ? "selected" : ""}`}
            onClick={() => setIsAnnual(true)}
          >
            Annual
          </div>
        </div>
      </div>

      {/* Pricing Cards */}
      <div className="pricing-cards mb-5">
        <div
          className="card-pricing random-card-pricing"
          onClick={() => handleSubscribe("starter")}
        >
          <h2>Starter</h2>
          <p className="price">R{pricing.starter}</p>
          <ul>
            <li>Basic plan included</li>
            <li>Limited access</li>
            <li>1 - 100 employees</li>
            <li>Predefined Roles</li>
          </ul>
          <button className="start-btn-pricing">Subscribe</button>
        </div>

        <div
          className="card-pricing pro-card-pricing"
          onClick={() => handleSubscribe("pro")}
        >
          <h2>Pro</h2>
          <p className="price">R{pricing.pro}</p>
          <ul>
            <li>Pro plan included</li>
            <li>Free support</li>
            <li>Limited access</li>
            <li>500 - 3k employees</li>
          </ul>
          <button className="start-btn-pricing">Subscribe</button>
        </div>

        <div
          className="card-pricing enterprise-card-pricing"
          onClick={() => setIstoggleContact(!istoggleContact)}
        >
          <h2>Enterprise</h2>
          <p className="price">{pricing.enterprise}</p>
          <ul>
            <li>Everything in Pro</li>
            <li>3k+ employees</li>
            <li>Free support</li>
            <li>Limited access</li>
          </ul>
          <button className="start-btn-pricing">Contact Us</button>
        </div>
      </div>

      {/* Custom Footer */}
      <div className="pricing-footer">
        <div className="footer-content">
          <div>© {currentYear} HC Kago. All rights reserved.</div>
          <div>
            <a href="#" className="footer-link">
              Privacy Policy
            </a>{" "}
            |
            <a href="#" className="footer-link">
              Terms of Service
            </a>
          </div>
        </div>
      </div>

      <OffCanvas
        isOffcanvasOpen={istoggleContact}
        title="Contact Us"
        body={<ContactUs />}
        toggleSaveChanges={() => setIstoggleContact(!istoggleContact)}
        directionOffCanvas="end"
        widthOffCanvas={400}
      />
    </div>
  );
};

export default PricingPage;
