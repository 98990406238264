import React from "react";

import { AiOutlineSearch } from "react-icons/ai";
const SearchInput = ({ Title, search, setSearch, radius }) => {
  const handleSearch = (event) => {
    setSearch(event.target.value);
  };
  return (
    <div
      style={{
        // marginTop: 15,
        width: 300,
        height: "3em",
        display: "flex",
        alignItems: "center",
        background: "#ffffff",
        paddingTop: ".58rem",
        paddingBottom: ".5rem",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        marginRight: 32,
        border: "solid",
        borderWidth: 0.1,
        borderRadius: radius,
      }}
    >
      <AiOutlineSearch size={24} />
      <input
        type="text"
        placeholder={Title}
        value={search}
        onChange={handleSearch}
        style={{
          border: "none",
          marginLeft: 8,
          paddingRight: 24,
        }}
      />
    </div>
  );
};

export default SearchInput;
