import React, { createContext, useState } from "react";

// Create the context
export const UserSubscriptionContext = createContext();

// Create a provider component
export const UserSubscriptionProvider = ({ children }) => {
  const [customerDetails, setCustomerDetails] = useState({
    name_first: "",
    name_last: "",
    phoneNumber: "",
    email_address: "",
  });

  return (
    <UserSubscriptionContext.Provider
      value={{ customerDetails, setCustomerDetails }}
    >
      {children}
    </UserSubscriptionContext.Provider>
  );
};
