import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Container, Wrapper, Title, SubTitle } from "./Style";
import MonthlyReviewChart from "../../../Common/MonthlyReviewChart";
import MyDepartmentalChart from "../../../Common/MyDepartmentalChart";
// myDeptOverall;
const MonthlyDepartmentAnalysisis = ({ myDeptOverall }) => {
  return (
    <React.Fragment>
      <Row>
        <Col md={6}>
          <Card style={Container}>
            <CardBody style={Wrapper}>
              <div style={Title}>Employee Monthly Review</div>
              <div>
                <MonthlyReviewChart />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md={6}>
          <Card style={Container}>
            <CardBody style={Wrapper}>
              <div style={Title}>Department Overall Performance</div>
              <div>
                <MyDepartmentalChart myDeptOverall={myDeptOverall} />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default MonthlyDepartmentAnalysisis;
