import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import usePost from "../../../lib/Hooks/usePost";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import ButtonSubmit from "../Buttons/ButtonSubmit";

const ContactUs = () => {
  const [inputs, setInputs] = useState({});
  const { status, execute, pending, data } = usePost();
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const Method = "POST",
      url = `auth/lead`;
    const raw = JSON.stringify({
      firstName: inputs.firstName,
      lastName: inputs.lastName,
      phoneNumber: inputs.phoneNumber,
      email: inputs.email,
      position: inputs.position,
      roles: inputs.roles,
    });
    execute(url, raw, Method, "Successfully sent", null, null);
  };

  return (
    <>
      <form
        noValidate
        autoComplete="off"
        data-testid="form"
        onSubmit={handleSubmit}
      >
        <Row>
          <Col md={12}>
            <label>
              First name <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              name="firstName"
              value={inputs.firstName}
              placeholder="Enter first name"
              onChange={handleChange}
              style={{ borderRadius: 7 }}
              className="mb-3 form-control fromInput"
              required
            />
          </Col>
          <Col md={12}>
            <label>
              Last name <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              name="lastName"
              value={inputs.lastName}
              placeholder="Enter last name"
              onChange={handleChange}
              style={{ borderRadius: 7 }}
              className="mb-3 form-control fromInput"
              required
            />
          </Col>
          <Col md={12}>
            <label>
              Email <span className="text-danger">*</span>
            </label>
            <input
              type="email"
              name="email"
              value={inputs.email}
              placeholder="Enter email"
              onChange={handleChange}
              style={{ borderRadius: 7 }}
              className="mb-3 form-control fromInput"
              required
            />
          </Col>
          <Col md={12}>
            <label>
              Phone Number <span className="text-danger">*</span>
            </label>
            <div className="phone-input-container">
              <PhoneInput
                international
                countryCallingCodeEditable={false}
                defaultCountry="ZA"
                value={inputs.phoneNumber ? inputs.phoneNumber : null}
                onChange={(value) =>
                  setInputs({ ...inputs, phoneNumber: value })
                }
                className="custom-phone-input"
              />
            </div>
          </Col>
          <Col md={12} className="mt-2">
            <label>
              How many employees <span className="text-danger">*</span>
            </label>
            <input
              type="number"
              name="employeeNumber"
              value={inputs.employeeNumber}
              onChange={handleChange}
              style={{ borderRadius: 7 }}
              className="mb-3 form-control fromInput"
              required
            />
          </Col>

          <div className="w-100 mt-3 d-flex justify-content-center">
            <ButtonSubmit
              Title="Send"
              ColorText="white"
              BorderColor="#33A6CD"
              BackgroundColor="#33A6CD"
              borderRadius="25px"
              handleOnclick={handleSubmit}
              pending={pending}
            />
          </div>
        </Row>
      </form>
    </>
  );
};

export default ContactUs;
