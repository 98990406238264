import React, { useState, useEffect } from "react";
import useCustomMutation from "../../../../lib/Hooks/useCustomMutation";
import { Row, Col } from "reactstrap";
import LogoEdit from "../../../Common/Profile/LogoEdit";
import ButtonSubmit from "../../../Common/Buttons/ButtonSubmit";

const EditCompany = ({ organizationID, organizationDetails, token }) => {
  const [showImage, setShowImage] = useState(null);
  const [uploadImg, setUploadImg] = useState(null);
  const [inputs, setInputs] = useState({
    name: "",
    size: "",
  });
  const key = ["CompanyDetails", organizationID];
  const mutation = useCustomMutation(key);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  const toggleSaveChanges = (e) => {
    e.preventDefault();

    const Method = "PATCH",
      url = `organizations/${organizationID}`,
      isJSON = true;
    var formdata = new FormData();
    if (uploadImg) {
      formdata.append("bannerImage", uploadImg);
    }
    Object.entries(inputs).forEach(([key, value]) => {
      if (value) formdata.append(key, value);
    });
    const data = {
      endPoint: url,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Company details successfully updated",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  useEffect(() => {
    if (organizationDetails?.data) {
      const { bannerImage, name, size } = organizationDetails?.data;
      setShowImage(bannerImage);
      setInputs({ name, size });
    }
  }, [organizationDetails?.data?._id]);

  return (
    <>
      <form
        noValidate
        autoComplete="off"
        data-testid="form"
        onSubmit={toggleSaveChanges}
      >
        <div className="d-flex justify-content-center">
          <Row style={{ width: "90%" }}>
            <Col md={12}>
              <div className="w-100 mb-2 d-flex justify-content-center">
                <h4>Company Details</h4>
              </div>
              <div className="w-100 d-flex justify-content-center">
                <LogoEdit
                  showImage={showImage}
                  setShowImage={setShowImage}
                  setUploadImg={setUploadImg}
                />
              </div>
              <div className="w-100 mt-2 mb-5 d-flex justify-content-center">
                logo size (400 X 200) pixels
              </div>
            </Col>
            <Col md={6}>
              <label>
                Company Name <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="name"
                value={inputs.name}
                placeholder="Enter Company name"
                onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control fromInput"
                required
              />
            </Col>
            <Col md={6}>
              <label>
                Company Size <span className="text-danger">*</span>
              </label>
              <input
                type="number"
                name="size"
                value={inputs.size}
                placeholder=""
                onChange={handleChange}
                style={{ borderRadius: 7 }}
                className="mb-3 form-control fromInput"
                required
              />
            </Col>
            {/* <Col md={4}>
            <label>
              Company Sector <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              name="companySector"
              value={inputs.companySector}
              placeholder="Enter Company sector"
              onChange={handleChange}
              style={{ borderRadius: 7 }}
              className="mb-3 form-control fromInput"
              required
            />
          </Col> */}

            {/* <Col md={4}></Col> */}
            <div className="mb-3"></div>
            <div className="w-100 d-flex justify-content-center">
              <ButtonSubmit
                Title="Update"
                ColorText="white"
                BorderColor="#33A6CD"
                BackgroundColor="#33A6CD"
                borderRadius="25px"
                handleOnclick={toggleSaveChanges}
                pending={mutation?.isLoading}
              />
            </div>
          </Row>
        </div>
      </form>
    </>
  );
};

export default EditCompany;
